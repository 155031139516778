import React from "react";
import {Row, Col, Tab, Nav, Accordion} from "react-bootstrap";
import { Fade } from "react-awesome-reveal";
import {GetText} from "../data/lang";

export const Services = () => {

    let text = GetText();

    return (
        <div id='services' className='text-center'>
            <div className='container'>
                <div className='section-title'>
                    <h2>{text ? text.ServicesInfo.title : ""}</h2>

                </div>

                {<div className='row desctop-tabs'>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="0">
                        <Row>
                            <Col md={3} className="del-padding">
                                <Fade>
                                    <Nav variant="pills" activeKey="0" className="flex-column for-adds">

                                        {text.Services
                                            ? text.Services.map((d, i) => (


                                                <Nav.Item key={i} className="services-tab-item">
                                                    <Nav.Link eventKey={i}>
                                                        <div className="text-left">
                                                            <h3 className="serv-item">
                                                                <div className="col-xs-3 col-sm-12 col-lg-3"><i className={d.icon}></i></div>
                                                                <div className="col-xs-9 col-sm-12 col-lg-9 services-name">{d.name}</div>
                                                            </h3>
                                                        </div>
                                                    </Nav.Link>
                                                </Nav.Item>

                                            ))
                                            : "Loading..."}


                                    </Nav>
                                </Fade>
                            </Col>
                            <Col md={9} className="back-col">
                                <Fade right>
                                    <Tab.Content>
                                        {text.Services
                                            ? text.Services.map((d, i) => (

                                                <Tab.Pane key={i} className="accordion text-left services-tab" eventKey={i}>
                                                    <ul>
                                                        {d.activ1 ? <li>{d.activ1}</li> : ""}
                                                        {d.activ2 ? <li>{d.activ2}</li> : ""}
                                                        {d.activ3 ? <li>{d.activ3}</li> : ""}
                                                        {d.activ4 ? <li>{d.activ4}</li> : ""}
                                                        {d.activ5 ? <li>{d.activ5}</li> : ""}
                                                        {d.activ6 ? <li>{d.activ6}</li> : ""}

                                                    </ul>
                                                    <p>{d.text}</p>
                                                </Tab.Pane>

                                            ))
                                            : "Loading..."}
                                    </Tab.Content>
                                </Fade>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>}
                {<div className="row mobile-tabs">

                    <Accordion defaultActiveKey="0">
                        {text.Services
                            ? text.Services.map((d, i) => (

                                <Accordion.Item  key={i} eventKey={i}>
                                    <Accordion.Header>
                                        <div className="text-left">
                                            <div className="accordion-title_left">
                                                <div className="accordion_icon-left"><i className={d.icon}></i></div>
                                                <div className="accordion_name">{d.name}</div>
                                            </div>
                                            <div className="accordion_icon-right"><i className="fa fa-chevron-down"></i></div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            {d.activ1 ? <li>{d.activ1}</li> : ""}
                                            {d.activ2 ? <li>{d.activ2}</li> : ""}
                                            {d.activ3 ? <li>{d.activ3}</li> : ""}
                                            {d.activ4 ? <li>{d.activ4}</li> : ""}
                                            {d.activ5 ? <li>{d.activ5}</li> : ""}
                                            {d.activ6 ? <li>{d.activ6}</li> : ""}
                                        </ul>
                                        <p>{d.text}</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))
                            : "Loading..."}
                    </Accordion>
                </div>}
            </div>
        </div>
    )
}
