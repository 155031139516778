import React, {useContext, useEffect, useState} from "react";
import {Navigation} from "../navigation.jsx";
import {useParams} from "react-router-dom";
import {Footer} from "../Footer";
import {GetText} from "../../data/lang";
import {Helmet} from "react-helmet";
import {StateContext} from "../../context/GlobalState";

export const NewsDetail = () => {

    // получение данных
    const {newsLink, lng} = useParams();
    const [newsDetail, setNewsDetail] = useState([]);
    const {lang} = useContext(StateContext);

    useEffect(() => {
        fetch(`https://muk.group/backend/api/newsDetail/read.php?news=${newsLink}`)
            .then(response => response.json()) // get json data from api
            .then(data =>
                setNewsDetail(data)
            ) // save data to `state`
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let text = GetText();

    return (
        <div>
            <div className="news-wrapper">
                <Navigation lng={lng}/>
                <div className="news-detail menu-indent">
                    {newsDetail ? newsDetail.map((news, i) =>
                        <div key={i}>
                            <Helmet htmlAttributes={{lang: lng}}>
                                {lang === "ua" ? <title>MUK | {news.title_ua}</title> : ""}
                                {lang === "ru" ? <title>MUK | {news.title_ru}</title> : ""}
                                {lang === "en" ? <title>MUK | {news.title_en}</title> : ""}
                                {lang === "az" ? <title>MUK | {news.title_az}</title> : ""}
                                {lang === "al" ? <title>MUK | {news.title_al}</title> : ""}
                                {lang === "am" ? <title>MUK | {news.title_am}</title> : ""}
                                {lang === "ge" ? <title>MUK | {news.title_ge}</title> : ""}
                                {lang === "kz" ? <title>MUK | {news.title_kz}</title> : ""}
                                {lang === "kg" ? <title>MUK | {news.title_kg}</title> : ""}
                                {lang === "mk" ? <title>MUK | {news.title_mk}</title> : ""}
                                {lang === "md" ? <title>MUK | {news.title_md}</title> : ""}
                                {lang === "mn" ? <title>MUK | {news.title_mn}</title> : ""}
                                {lang === "si" ? <title>MUK | {news.title_si}</title> : ""}
                                {lang === "tj" ? <title>MUK | {news.title_tj}</title> : ""}
                                {lang === "tr" ? <title>MUK | {news.title_tr}</title> : ""}
                                {lang === "tm" ? <title>MUK | {news.title_tm}</title> : ""}
                                {lang === "uz" ? <title>MUK | {news.title_uz}</title> : ""}
                                {lang === "hr" ? <title>MUK | {news.title_hr}</title> : ""}
                                {lang === "rs" ? <title>MUK | {news.title_rs}</title> : ""}
                                {lang === "bg" ? <title>MUK | {news.title_bg}</title> : ""}
                                {lang === "ua" ? <meta name="description" content={news.description_short_ua}/> : ""}
                                {lang === "ru" ? <meta name="description" content={news.description_short_ru}/> : ""}
                                {lang === "en" ? <meta name="description" content={news.description_short_en}/> : ""}
                                {lang === "az" ? <meta name="description" content={news.description_short_az}/> : ""}
                                {lang === "al" ? <meta name="description" content={news.description_short_al}/> : ""}
                                {lang === "am" ? <meta name="description" content={news.description_short_am}/> : ""}
                                {lang === "ge" ? <meta name="description" content={news.description_short_ge}/> : ""}
                                {lang === "kz" ? <meta name="description" content={news.description_short_kz}/> : ""}
                                {lang === "kg" ? <meta name="description" content={news.description_short_kg}/> : ""}
                                {lang === "mk" ? <meta name="description" content={news.description_short_mk}/> : ""}
                                {lang === "md" ? <meta name="description" content={news.description_short_md}/> : ""}
                                {lang === "mn" ? <meta name="description" content={news.description_short_mn}/> : ""}
                                {lang === "si" ? <meta name="description" content={news.description_short_si}/> : ""}
                                {lang === "tj" ? <meta name="description" content={news.description_short_tj}/> : ""}
                                {lang === "tr" ? <meta name="description" content={news.description_short_tr}/> : ""}
                                {lang === "tm" ? <meta name="description" content={news.description_short_tm}/> : ""}
                                {lang === "uz" ? <meta name="description" content={news.description_short_uz}/> : ""}
                                {lang === "hr" ? <meta name="description" content={news.description_short_hr}/> : ""}
                                {lang === "rs" ? <meta name="description" content={news.description_short_rs}/> : ""}
                                {lang === "bg" ? <meta name="description" content={news.description_short_bg}/> : ""}

                                <meta name="keywords" content='news, новость, muk'/>
                            </Helmet>
                            <div className="news-title">
                                <div className="container">
                                    <svg className='team-img' viewBox="0 0 145 55" width="145" height="55">
                                        <rect x="1" y="1" width="143" height="53" rx="6" ry="6" stroke="rgb(179, 179, 179)" stroke-width="1" fill="white" />
                                        <image   href={`/img/vendors/${news.image}`} x="0" y="0" width="145" height="55" />
                                    </svg>

                                  {/*  <img className="news-info_logo"
                                         src={news.image ? `/img/vendors/${news.image}` : "/img/vendors/muk.png"}
                                         title="news" alt='news'/>*/}
                                    <h3 className="news-title-padding">
                                        {lang === "ua" ? news.title_ua : ""}
                                        {lang === "ru" ? news.title_ru : ""}
                                        {lang === "en" ? news.title_en : ""}
                                        {lang === "az" ? news.title_az : ""}
                                        {lang === "al" ? news.title_al : ""}
                                        {lang === "am" ? news.title_am : ""}
                                        {lang === "ge" ? news.title_ge : ""}
                                        {lang === "kz" ? news.title_kz : ""}
                                        {lang === "kg" ? news.title_kg : ""}
                                        {lang === "mk" ? news.title_mk : ""}
                                        {lang === "md" ? news.title_md : ""}
                                        {lang === "mn" ? news.title_mn : ""}
                                        {lang === "si" ? news.title_si : ""}
                                        {lang === "tj" ? news.title_tj : ""}
                                        {lang === "tr" ? news.title_tr : ""}
                                        {lang === "tm" ? news.title_tm : ""}
                                        {lang === "uz" ? news.title_uz : ""}
                                        {lang === "hr" ? news.title_hr : ""}
                                        {lang === "rs" ? news.title_rs : ""}
                                        {lang === "bg" ? news.title_bg : ""}
                                    </h3>
                                </div>
                            </div>

                            <div className="news-info">
                                <div className="container">
                                    <div className="news-info_left">
                                        <p className="news-name">
                                            {news.type === "1" ? text.News.news : ""}
                                            {news.type === "2" ? text.News.akcii : ""}
                                        </p>
                                    </div>

                                    <p className="news-date">
                                        {news.date.length ? news.date : ""}
                                    </p>
                                </div>
                            </div>
                            <div className="news-body">
                                {lang === "ua" ? <div className="container">
                                    <p className="news-subtitle">{news.description_short_ua.length ? news.description_short_ua : ""}</p>
                                    <p className="news-text">{news.description_ua.length ?
                                        <div dangerouslySetInnerHTML={{__html: news.description_ua}}/> : ""}</p>
                                </div> : ""}

                                {lang === "ru" ? <div className="container">
                                    <p className="news-subtitle">{news.description_short_ru.length ? news.description_short_ru : ""}</p>
                                    <p className="news-text">{news.description_ru.length ?
                                        <div dangerouslySetInnerHTML={{__html: news.description_ru}}/> : ""}</p>
                                </div> : ""}

                                {lang === "en" ? <div className="container">
                                    <p className="news-subtitle">{news.description_short_en.length ? news.description_short_en : ""}</p>
                                    <p className="news-text">{news.description_en.length ?
                                        <div dangerouslySetInnerHTML={{__html: news.description_en}}/> : ""}</p>
                                </div> : ""}

                                {lang === "az" ? <div className="container">
                                    <p className="news-subtitle">{news.description_short_az.length ? news.description_short_az : ""}</p>
                                    <p className="news-text">{news.description_az.length ?
                                        <div dangerouslySetInnerHTML={{__html: news.description_az}}/> : ""}</p>
                                </div> : ""}

                                {lang === "al" ? <div className="container">
                                    <p className="news-subtitle">{news.description_short_al.length ? news.description_short_al : ""}</p>
                                    <p className="news-text">{news.description_al.length ?
                                        <div dangerouslySetInnerHTML={{__html: news.description_al}}/> : ""}</p>
                                </div> : ""}

                                {lang === "am" ? <div className="container">
                                    <p className="news-subtitle">{news.description_short_am.length ? news.description_short_am : ""}</p>
                                    <p className="news-text">{news.description_am.length ?
                                        <div dangerouslySetInnerHTML={{__html: news.description_am}}/> : ""}</p>
                                </div> : ""}

                                {lang === "ge" ? <div className="container">
                                    <p className="news-subtitle">{news.description_short_ge.length ? news.description_short_ge : ""}</p>
                                    <p className="news-text">{news.description_ge.length ?
                                        <div dangerouslySetInnerHTML={{__html: news.description_ge}}/> : ""}</p>
                                </div> : ""}

                                {lang === "kz" ? <div className="container">
                                    <p className="news-subtitle">{news.description_short_kz.length ? news.description_short_kz : ""}</p>
                                    <p className="news-text">{news.description_kz.length ?
                                        <div dangerouslySetInnerHTML={{__html: news.description_kz}}/> : ""}</p>
                                </div> : ""}

                                {lang === "kg" ? <div className="container">
                                    <p className="news-subtitle">{news.description_short_kg.length ? news.description_short_kg : ""}</p>
                                    <p className="news-text">{news.description_kg.length ?
                                        <div dangerouslySetInnerHTML={{__html: news.description_kg}}/> : ""}</p>
                                </div> : ""}

                                {lang === "mk" ? <div className="container">
                                    <p className="news-subtitle">{news.description_short_mk.length ? news.description_short_mk : ""}</p>
                                    <p className="news-text">{news.description_mk.length ?
                                        <div dangerouslySetInnerHTML={{__html: news.description_mk}}/> : ""}</p>
                                </div> : ""}

                                {lang === "md" ? <div className="container">
                                    <p className="news-subtitle">{news.description_short_md.length ? news.description_short_md : ""}</p>
                                    <p className="news-text">{news.description_md.length ?
                                        <div dangerouslySetInnerHTML={{__html: news.description_md}}/> : ""}</p>
                                </div> : ""}

                                {lang === "mn" ? <div className="container">
                                    <p className="news-subtitle">{news.description_short_mn.length ? news.description_short_mn : ""}</p>
                                    <p className="news-text">{news.description_mn.length ?
                                        <div dangerouslySetInnerHTML={{__html: news.description_mn}}/> : ""}</p>
                                </div> : ""}

                                {lang === "si" ? <div className="container">
                                    <p className="news-subtitle">{news.description_short_si.length ? news.description_short_si : ""}</p>
                                    <p className="news-text">{news.description_si.length ?
                                        <div dangerouslySetInnerHTML={{__html: news.description_si}}/> : ""}</p>
                                </div> : ""}

                                {lang === "tj" ? <div className="container">
                                    <p className="news-subtitle">{news.description_short_tj.length ? news.description_short_tj : ""}</p>
                                    <p className="news-text">{news.description_tj.length ?
                                        <div dangerouslySetInnerHTML={{__html: news.description_tj}}/> : ""}</p>
                                </div> : ""}

                                {lang === "tr" ? <div className="container">
                                    <p className="news-subtitle">{news.description_short_tr.length ? news.description_short_tr : ""}</p>
                                    <p className="news-text">{news.description_tr.length ?
                                        <div dangerouslySetInnerHTML={{__html: news.description_tr}}/> : ""}</p>
                                </div> : ""}

                                {lang === "tm" ? <div className="container">
                                    <p className="news-subtitle">{news.description_short_tm.length ? news.description_short_tm : ""}</p>
                                    <p className="news-text">{news.description_tm.length ?
                                        <div dangerouslySetInnerHTML={{__html: news.description_tm}}/> : ""}</p>
                                </div> : ""}

                                {lang === "uz" ? <div className="container">
                                    <p className="news-subtitle">{news.description_short_uz.length ? news.description_short_uz : ""}</p>
                                    <p className="news-text">{news.description_uz.length ?
                                        <div dangerouslySetInnerHTML={{__html: news.description_uz}}/> : ""}</p>
                                </div> : ""}

                                {lang === "hr" ? <div className="container">
                                    <p className="news-subtitle">{news.description_short_hr.length ? news.description_short_hr : ""}</p>
                                    <p className="news-text">{news.description_hr.length ?
                                        <div dangerouslySetInnerHTML={{__html: news.description_hr}}/> : ""}</p>
                                </div> : ""}

                                {lang === "rs" ? <div className="container">
                                    <p className="news-subtitle">{news.description_short_rs.length ? news.description_short_rs : ""}</p>
                                    <p className="news-text">{news.description_rs.length ?
                                        <div dangerouslySetInnerHTML={{__html: news.description_rs}}/> : ""}</p>
                                </div> : ""}

                                {lang === "bg" ? <div className="container">
                                    <p className="news-subtitle">{news.description_short_bg.length ? news.description_short_bg : ""}</p>
                                    <p className="news-text">{news.description_bg.length ?
                                        <div dangerouslySetInnerHTML={{__html: news.description_bg}}/> : ""}</p>
                                </div> : ""}
                            </div>
                        </div>
                    ) : "Loading..."}

                </div>
                <Footer/>
            </div>
        </div>
    );
};
