import React from "react";
import {Navigation} from "../navigation.jsx";
import {Footer} from "../Footer";
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import {GetText} from "../../data/lang";

export const Privacy = () => {

    const {lng} = useParams();
    let text = GetText();

    return (
        <div>
            <Helmet htmlAttributes={{ lang : lng }}>
                <title>MUK | {text.Privacy.title}</title>
                <meta name="description" content={text.Privacy.title} />
                <meta name="keywords"  content='мук, muk, about, о компании' />
            </Helmet>
            <Navigation lng={lng}/>
            <div className="solution-detail menu-indent">
                <div className="solution-title">
                    <div className="container">
                        <h3>
                            {text.Privacy.title}
                        </h3>
                    </div>
                </div>
                <div className="container">
                    <br/><br/>
                    <p>{text.Privacy.p1}</p>
                    <h3>{text.Privacy.bp1}</h3>
                    <p>{text.Privacy.p2}</p>
                    <p>{text.Privacy.p3}</p>
                    <h3>{text.Privacy.bp2}</h3>
                    <p>{text.Privacy.p4}</p>
                    <h3>{text.Privacy.bp3}</h3>
                    <p>{text.Privacy.p5}</p>
                    <h3>{text.Privacy.bp4}</h3>
                    <p>{text.Privacy.p6}</p>
                    <h3>{text.Privacy.bp5}</h3>
                    <p>{text.Privacy.p7}</p>
                    <p>{text.Privacy.p8}</p>
                    <br/><br/>

                </div>
            </div>

            <Footer/>
        </div>
    );
};
