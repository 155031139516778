import React, {useContext, useEffect, useState} from "react";
import {Navigation} from "../navigation.jsx";
import {Footer} from "../Footer";
import {StateContext} from "../../context/GlobalState";
import {Link, useParams} from "react-router-dom";
import {GetText} from "../../data/lang";
import {Helmet} from "react-helmet";

export const Vacanies = () => {
    const {lng} = useParams();
    const {region} = useParams();
    const {jobsList, countryList, lang, geo, activeCountryVac, setActiveCountryVac} = useContext(StateContext);
    const [jobsShow, setJobsShow] = useState(jobsList);  //массив который отображаем (верстка)
    const [jNameSearch, setjNameSearch] = useState("");
    const [jCountrySearch, setjCountrySearch] = useState("");
    let jobsM = "";

   // console.log(jobsList);
   // console.log (activeCountryVac);
    // фильтр Азербайджана и Армении
    let countryListMod = countryList;
    if(geo === "az"){ countryListMod = countryList.filter((c) =>  c.iso !== "am" );}
    if(geo === "am"){ countryListMod = countryList.filter((c) =>  c.iso !== "az" );}
    let jobsListMod = jobsList;
    if(geo === "az"){ jobsListMod = jobsList.filter((j) =>  j.id_country !== "1" );}
    if(geo === "am"){ jobsListMod = jobsList.filter((j) =>  j.id_country !== "20" );}

    //if(region){ countryListMod = countryList.filter((c) =>  c.iso === region );}



    //функция поиск по имени
    let jobsNameSearch = (e) => {
        if (e.target.value) {
            setjNameSearch(e.target.value);

        } else {
            setjNameSearch("");
        }
    }

    //функция выбора страны
    let selectCountry = (e) => {
        console.log(e);
            if (e.target.value) {
                setjCountrySearch(e.target.value);
                setActiveCountryVac({ value: e.target.value, name: e.target[e.target.selectedIndex].text});
            } else {
                setjCountrySearch("");
            }
    }


    useEffect(() => {
        refreshVacancies(jNameSearch, jCountrySearch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jNameSearch])

    useEffect(() => {
        refreshVacancies(jNameSearch, jCountrySearch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jCountrySearch])

    useEffect(() => {

            if(region === "am"){ jobsListMod = jobsList.filter((j) =>  j.id_country === "1" );}
            if(region === "by"){ jobsListMod = jobsList.filter((j) =>  j.id_country === "2" );}
            if(region === "ge"){ jobsListMod = jobsList.filter((j) =>  j.id_country === "3" );}
            if(region === "kz"){ jobsListMod = jobsList.filter((j) =>  j.id_country === "4" );}
            if(region === "kg"){ jobsListMod = jobsList.filter((j) =>  j.id_country === "5" );}
            if(region === "md"){ jobsListMod = jobsList.filter((j) =>  j.id_country === "6" );}
            if(region === "mn"){ jobsListMod = jobsList.filter((j) =>  j.id_country === "7" );}
            if(region === "tj"){ jobsListMod = jobsList.filter((j) =>  j.id_country === "8" );}
            if(region === "tm"){ jobsListMod = jobsList.filter((j) =>  j.id_country === "9" );}
            if(region === "uz"){ jobsListMod = jobsList.filter((j) =>  j.id_country === "10" );}
            if(region === "ua"){ jobsListMod = jobsList.filter((j) =>  j.id_country === "11" );}
            if(region === "al"){ jobsListMod = jobsList.filter((j) =>  j.id_country === "12" );}
            if(region === "mk"){ jobsListMod = jobsList.filter((j) =>  j.id_country === "15" );}
            if(region === "rs"){ jobsListMod = jobsList.filter((j) =>  j.id_country === "16" );}
            if(region === "si"){ jobsListMod = jobsList.filter((j) =>  j.id_country === "17" );}
            if(region === "hr"){ jobsListMod = jobsList.filter((j) =>  j.id_country === "18" );}
            if(region === "az"){ jobsListMod = jobsList.filter((j) =>  j.id_country === "20" );}
            if(region === "tr"){ jobsListMod = jobsList.filter((j) =>  j.id_country === "21" );}
            if(region === "bg"){ jobsListMod = jobsList.filter((j) =>  j.id_country === "22" );}

            if(activeCountryVac.value) {
                jobsListMod = jobsList.filter((j) =>  j.id_country === activeCountryVac.value );
                console.log(activeCountryVac.value);
            }

            setJobsShow(jobsListMod);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobsListMod])



    //Вывод логотипов
    let refreshVacancies = (nm, cntr) => {
        if (nm) {
            if(lang === "ua")
            { jobsM = jobsListMod.filter(job => job.title_ua.toLowerCase().includes(nm.toLowerCase()));}

            if(lang === "ru")
            { jobsM = jobsListMod.filter(job => job.title_ru.toLowerCase().includes(nm.toLowerCase()));}

            if(lang === "en")
            { jobsM = jobsListMod.filter(job => job.title_en.toLowerCase().includes(nm.toLowerCase()));}

            if(lang === "az")
            { jobsM = jobsListMod.filter(job => job.title_az.toLowerCase().includes(nm.toLowerCase()));}

            if(lang === "al")
            { jobsM = jobsListMod.filter(job => job.title_al.toLowerCase().includes(nm.toLowerCase()));}

            if(lang === "am")
            { jobsM = jobsListMod.filter(job => job.title_am.toLowerCase().includes(nm.toLowerCase()));}

            if(lang === "ge")
            { jobsM = jobsListMod.filter(job => job.title_ge.toLowerCase().includes(nm.toLowerCase()));}

            if(lang === "kg")
            { jobsM = jobsListMod.filter(job => job.title_kg.toLowerCase().includes(nm.toLowerCase()));}

            if(lang === "kz")
            { jobsM = jobsListMod.filter(job => job.title_kz.toLowerCase().includes(nm.toLowerCase()));}

            if(lang === "mk")
            { jobsM = jobsListMod.filter(job => job.title_mk.toLowerCase().includes(nm.toLowerCase()));}

            if(lang === "md")
            { jobsM = jobsListMod.filter(job => job.title_md.toLowerCase().includes(nm.toLowerCase()));}

            if(lang === "mn")
            { jobsM = jobsListMod.filter(job => job.title_mn.toLowerCase().includes(nm.toLowerCase()));}

            if(lang === "si")
            { jobsM = jobsListMod.filter(job => job.title_si.toLowerCase().includes(nm.toLowerCase()));}

            if(lang === "tj")
            { jobsM = jobsListMod.filter(job => job.title_tj.toLowerCase().includes(nm.toLowerCase()));}

            if(lang === "tr")
            { jobsM = jobsListMod.filter(job => job.title_tr.toLowerCase().includes(nm.toLowerCase()));}

            if(lang === "tm")
            { jobsM = jobsListMod.filter(job => job.title_tm.toLowerCase().includes(nm.toLowerCase()));}

            if(lang === "uz")
            { jobsM = jobsListMod.filter(job => job.title_uz.toLowerCase().includes(nm.toLowerCase()));}

            if(lang === "hr")
            { jobsM = jobsListMod.filter(job => job.title_hr.toLowerCase().includes(nm.toLowerCase()));}

            if(lang === "rs")
            { jobsM = jobsListMod.filter(job => job.title_rs.toLowerCase().includes(nm.toLowerCase()));}

            if(lang === "bg")
            { jobsM = jobsListMod.filter(job => job.title_bg.toLowerCase().includes(nm.toLowerCase()));}



            if (cntr) {
                jobsM = jobsM.filter(job => job.id_country === cntr ? true : false);
            }
        }
        else{
            if (cntr) {
                jobsM = jobsListMod.filter(job => job.id_country === cntr ? true : false);
            }
        }



        //console.log(c);
        //console.log(n);
        if(jobsM){
            setJobsShow(jobsM);
        }
        else{
            setJobsShow(jobsListMod);
        }

    }

    let text = GetText();

    return (
        <div>
        <div>
            <Helmet htmlAttributes={{ lang : lng }}>
                <title>MUK | {text.Vacancies.title}</title>
                <meta name="description" content={text.Vacancies.title} />
                <meta name="keywords"  content='мук, muk, jobs, Career, вакансии' />
            </Helmet>
            <Navigation lng={lng}/>
            <div className="vacancies menu-indent">
                <div className="container">
                    <div className='col-md-12 section-title'>
                        <h2>{text.Vacancies.title}</h2>
                        <Link to={`/${lang}/resume`} type='button' className='btn btn-custom btn-lg'>{text ? text.Vacancies.resume: ""}</Link>
                    </div>
                    <div className='col-md-12 search-block'>

                        <form action="">
                            <div>
                                <label htmlFor="Country">{text.Vacancies.country}</label>
                                <select name="" onChange={
                                    selectCountry} id="Country">

                                    {activeCountryVac.value ? <option value={activeCountryVac.value}> {activeCountryVac.name} </option> : <option value="">{text.Vacancies.all}</option> }

                                    {countryListMod ? countryListMod.map((country, i) => <option key={i}
                                                                                           value={country.id}>
                                        {lang === "ua" ? country.name_ua : ""}
                                        {lang === "ru" ? country.name_ru : ""}
                                        {lang === "en" ? country.name_en : ""}
                                        {lang === "az" ? country.name_az : ""}
                                        {lang === "al" ? country.name_al : ""}
                                        {lang === "am" ? country.name_am : ""}
                                        {lang === "ge" ? country.name_ge : ""}
                                        {lang === "kz" ? country.name_kz : ""}
                                        {lang === "kg" ? country.name_kg : ""}
                                        {lang === "mk" ? country.name_mk : ""}
                                        {lang === "md" ? country.name_md : ""}
                                        {lang === "mn" ? country.name_mn : ""}
                                        {lang === "si" ? country.name_si : ""}
                                        {lang === "tj" ? country.name_tj : ""}
                                        {lang === "tr" ? country.name_tr : ""}
                                        {lang === "tm" ? country.name_tm : ""}
                                        {lang === "uz" ? country.name_uz : ""}
                                        {lang === "hr" ? country.name_hr : ""}
                                        {lang === "rs" ? country.name_rs : ""}
                                        {lang === "bg" ? country.name_bg : ""}
                                    </option>) : "loading"}
                                </select>
                            </div>
                            <div>
                                <label htmlFor="filterName">{text.Vacancies.search}</label>
                                <input type="text" onChange={jobsNameSearch} id="filterName"
                                       placeholder={text.Vacancies.namePlaceholder} />
                            </div>
                        </form>
                    </div>
                    <div className="col-md-12 vacancies-block">
                        {jobsShow.length > 0 ? jobsShow.map((job, i) => <div key={i} className="vacancies-block_item">
                            <Link title={job.title_en} to={`/${lang}/vacancies/${job.id_parent}`}>
                                {lang === "ua" ? <p>{countryListMod.length > 0 ? countryListMod.map(CL => CL.id === job.id_country ? CL.name_ua : "") : ""}</p> : ""}
                                {lang === "ru" ? <p>{countryListMod.length > 0 ? countryListMod.map(CL => CL.id === job.id_country ? CL.name_ru : "") : ""}</p> : ""}
                                {lang === "en" ? <p>{countryListMod.length > 0 ? countryListMod.map(CL => CL.id === job.id_country ? CL.name_en : "") : ""}</p> : ""}
                                {lang === "az" ? <p>{countryListMod.length > 0 ? countryListMod.map(CL => CL.id === job.id_country ? CL.name_az : "") : ""}</p> : ""}
                                {lang === "al" ? <p>{countryListMod.length > 0 ? countryListMod.map(CL => CL.id === job.id_country ? CL.name_al : "") : ""}</p> : ""}
                                {lang === "am" ? <p>{countryListMod.length > 0 ? countryListMod.map(CL => CL.id === job.id_country ? CL.name_am : "") : ""}</p> : ""}
                                {lang === "ge" ? <p>{countryListMod.length > 0 ? countryListMod.map(CL => CL.id === job.id_country ? CL.name_ge : "") : ""}</p> : ""}
                                {lang === "kz" ? <p>{countryListMod.length > 0 ? countryListMod.map(CL => CL.id === job.id_country ? CL.name_kz : "") : ""}</p> : ""}
                                {lang === "kg" ? <p>{countryListMod.length > 0 ? countryListMod.map(CL => CL.id === job.id_country ? CL.name_kg : "") : ""}</p> : ""}
                                {lang === "mk" ? <p>{countryListMod.length > 0 ? countryListMod.map(CL => CL.id === job.id_country ? CL.name_mk : "") : ""}</p> : ""}
                                {lang === "md" ? <p>{countryListMod.length > 0 ? countryListMod.map(CL => CL.id === job.id_country ? CL.name_md : "") : ""}</p> : ""}
                                {lang === "mn" ? <p>{countryListMod.length > 0 ? countryListMod.map(CL => CL.id === job.id_country ? CL.name_mn : "") : ""}</p> : ""}
                                {lang === "si" ? <p>{countryListMod.length > 0 ? countryListMod.map(CL => CL.id === job.id_country ? CL.name_si : "") : ""}</p> : ""}
                                {lang === "tj" ? <p>{countryListMod.length > 0 ? countryListMod.map(CL => CL.id === job.id_country ? CL.name_tj : "") : ""}</p> : ""}
                                {lang === "tr" ? <p>{countryListMod.length > 0 ? countryListMod.map(CL => CL.id === job.id_country ? CL.name_tr : "") : ""}</p> : ""}
                                {lang === "tm" ? <p>{countryListMod.length > 0 ? countryListMod.map(CL => CL.id === job.id_country ? CL.name_tm : "") : ""}</p> : ""}
                                {lang === "uz" ? <p>{countryListMod.length > 0 ? countryListMod.map(CL => CL.id === job.id_country ? CL.name_uz : "") : ""}</p> : ""}
                                {lang === "hr" ? <p>{countryListMod.length > 0 ? countryListMod.map(CL => CL.id === job.id_country ? CL.name_hr : "") : ""}</p> : ""}
                                {lang === "rs" ? <p>{countryListMod.length > 0 ? countryListMod.map(CL => CL.id === job.id_country ? CL.name_rs : "") : ""}</p> : ""}
                                {lang === "bg" ? <p>{countryListMod.length > 0 ? countryListMod.map(CL => CL.id === job.id_country ? CL.name_bg : "") : ""}</p> : ""}
                                {lang === "ua" ? <p>{job.title_ua}</p> : ""}
                                {lang === "ru" ? <p>{job.title_ru}</p> : ""}
                                {lang === "en" ? <p>{job.title_en}</p> : ""}
                                {lang === "az" ? <p>{job.title_az}</p> : ""}
                                {lang === "al" ? <p>{job.title_al}</p> : ""}
                                {lang === "am" ? <p>{job.title_am}</p> : ""}
                                {lang === "ge" ? <p>{job.title_ge}</p> : ""}
                                {lang === "kz" ? <p>{job.title_kz}</p> : ""}
                                {lang === "kg" ? <p>{job.title_kg}</p> : ""}
                                {lang === "mk" ? <p>{job.title_mk}</p> : ""}
                                {lang === "md" ? <p>{job.title_md}</p> : ""}
                                {lang === "mn" ? <p>{job.title_mn}</p> : ""}
                                {lang === "si" ? <p>{job.title_si}</p> : ""}
                                {lang === "tj" ? <p>{job.title_tj}</p> : ""}
                                {lang === "tr" ? <p>{job.title_tr}</p> : ""}
                                {lang === "tm" ? <p>{job.title_tm}</p> : ""}
                                {lang === "uz" ? <p>{job.title_uz}</p> : ""}
                                {lang === "hr" ? <p>{job.title_hr}</p> : ""}
                                {lang === "rs" ? <p>{job.title_rs}</p> : ""}
                                {lang === "bg" ? <p>{job.title_bg}</p> : ""}
                            </Link>
                        </div>) : text.Vacancies.filterInfo }
                    </div>
                </div>
            </div>
            <br/><br/>
            <Footer/>
        </div>
        </div>
    );
};
