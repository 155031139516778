import React, {useContext, useEffect, useState} from "react";
import {Navigation} from "../navigation.jsx";
import {Link, useParams} from "react-router-dom";
import {Footer} from "../Footer";
import {GetText} from "../../data/lang";
import {Helmet} from "react-helmet";
import {StateContext} from "../../context/GlobalState";

export const VacaniesDetail = () => {

    // получение данных
    const {jobsLink, lng} = useParams();
    const [jobsDetail, setJobsDetail] = useState([]);
    const {countryList, lang} = useContext(StateContext);

    useEffect(() => {
        fetch(`https://muk.group/backend/api/jobsDetail/read.php?job=${jobsLink}`)
            .then(response => response.json()) // get json data from api
            .then(data =>
                setJobsDetail(data.list)
            ) // save data to `state`
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    let text = GetText();
    let country = "";
   // console.log(jobsDetail[0].id_country);
    if (jobsDetail.length > 0){ country = countryList.filter(CL => CL.id === jobsDetail[0].id_country); }
    else{ country = ""; }


    return (
        <div>
            <div className="news-wrapper">
                <Navigation lng={lng}/>
                <div className="news-detail menu-indent">
                {jobsDetail ? jobsDetail.map((jobs, i) =>
                    <div key={i}>
                        <Helmet htmlAttributes={{ lang : lng }}>
                            { lang === "ua" ? <title>MUK | {jobs.title_ua} </title> : ""}
                            { lang === "ru" ? <title>MUK | {jobs.title_ru} </title> : ""}
                            { lang === "en" ? <title>MUK | {jobs.title_en} </title> : ""}
                            { lang === "az" ? <title>MUK | {jobs.title_az} </title> : ""}
                            { lang === "al" ? <title>MUK | {jobs.title_al} </title> : ""}
                            { lang === "am" ? <title>MUK | {jobs.title_am} </title> : ""}
                            { lang === "ge" ? <title>MUK | {jobs.title_ge} </title> : ""}
                            { lang === "kz" ? <title>MUK | {jobs.title_kz} </title> : ""}
                            { lang === "kg" ? <title>MUK | {jobs.title_kg} </title> : ""}
                            { lang === "mk" ? <title>MUK | {jobs.title_mk} </title> : ""}
                            { lang === "md" ? <title>MUK | {jobs.title_md} </title> : ""}
                            { lang === "mn" ? <title>MUK | {jobs.title_mn} </title> : ""}
                            { lang === "si" ? <title>MUK | {jobs.title_si} </title> : ""}
                            { lang === "tj" ? <title>MUK | {jobs.title_tj} </title> : ""}
                            { lang === "tr" ? <title>MUK | {jobs.title_tr} </title> : ""}
                            { lang === "tm" ? <title>MUK | {jobs.title_tm} </title> : ""}
                            { lang === "uz" ? <title>MUK | {jobs.title_uz} </title> : ""}
                            { lang === "hr" ? <title>MUK | {jobs.title_hr} </title> : ""}
                            { lang === "rs" ? <title>MUK | {jobs.title_rs} </title> : ""}
                            { lang === "bg" ? <title>MUK | {jobs.title_bg} </title> : ""}
                            <meta name="keywords"  content='vacancies, вакансия, muk' />
                        </Helmet>
                        <div className="news-title">
                            <div className="container">
                                <h3>
                                    { lang === "ua" ? jobs.title_ua : ""}
                                    { lang === "ru" ? jobs.title_ru : ""}
                                    { lang === "en" ? jobs.title_en : ""}
                                    { lang === "az" ? jobs.title_az : ""}
                                    { lang === "al" ? jobs.title_al : ""}
                                    { lang === "am" ? jobs.title_am : ""}
                                    { lang === "ge" ? jobs.title_ge : ""}
                                    { lang === "kz" ? jobs.title_kz : ""}
                                    { lang === "kg" ? jobs.title_kg : ""}
                                    { lang === "mk" ? jobs.title_mk : ""}
                                    { lang === "md" ? jobs.title_md : ""}
                                    { lang === "mn" ? jobs.title_mn : ""}
                                    { lang === "si" ? jobs.title_si : ""}
                                    { lang === "tj" ? jobs.title_tj : ""}
                                    { lang === "tr" ? jobs.title_tr : ""}
                                    { lang === "tm" ? jobs.title_tm : ""}
                                    { lang === "uz" ? jobs.title_uz : ""}
                                    { lang === "hr" ? jobs.title_hr : ""}
                                    { lang === "rs" ? jobs.title_rs : ""}
                                    { lang === "bg" ? jobs.title_bg : ""}

                                </h3>
                            </div>
                        </div>
                        <div className="news-info">
                            <div className="container">
                                <div className="news-info_left">
                                    {country[0] ? lang === "ua" ? country[0].name_ua : "" : ""}
                                    {country[0] ? lang === "ru" ? country[0].name_ru : "" : ""}
                                    {country[0] ? lang === "en" ? country[0].name_en : "" : ""}
                                    {country[0] ? lang === "az" ? country[0].name_az : "" : ""}
                                    {country[0] ? lang === "al" ? country[0].name_al : "" : ""}
                                    {country[0] ? lang === "am" ? country[0].name_am : "" : ""}
                                    {country[0] ? lang === "ge" ? country[0].name_ge : "" : ""}
                                    {country[0] ? lang === "kz" ? country[0].name_kz : "" : ""}
                                    {country[0] ? lang === "kg" ? country[0].name_kg : "" : ""}
                                    {country[0] ? lang === "mk" ? country[0].name_mk : "" : ""}
                                    {country[0] ? lang === "md" ? country[0].name_md : "" : ""}
                                    {country[0] ? lang === "mn" ? country[0].name_mn : "" : ""}
                                    {country[0] ? lang === "si" ? country[0].name_si : "" : ""}
                                    {country[0] ? lang === "tj" ? country[0].name_tj : "" : ""}
                                    {country[0] ? lang === "tr" ? country[0].name_tr : "" : ""}
                                    {country[0] ? lang === "tm" ? country[0].name_tm : "" : ""}
                                    {country[0] ? lang === "uz" ? country[0].name_uz : "" : ""}
                                    {country[0] ? lang === "hr" ? country[0].name_hr : "" : ""}
                                    {country[0] ? lang === "rs" ? country[0].name_rs : "" : ""}
                                    {country[0] ? lang === "bg" ? country[0].name_bg : "" : ""}

                                </div>

                                    { lang === "ua" ?  <p className="news-date"><Link to={`/${lang}/vacancies`}  className='btn btn-custom btn-lg' >{text ? text.Vacancies.back : ""}</Link> <Link  to={`/${lang}/resume`} state={{vacancia: jobs.title_ua}} className='btn btn-custom btn-lg' >{text ? text.Vacancies.resume : ""}</Link></p> : "" }
                                    { lang === "ru" ?  <p className="news-date"><Link to={`/${lang}/vacancies`}  className='btn btn-custom btn-lg' >{text ? text.Vacancies.back : ""}</Link> <Link  to={`/${lang}/resume`} state={{vacancia: jobs.title_ru}} className='btn btn-custom btn-lg' >{text ? text.Vacancies.resume : ""}</Link></p> : "" }
                                    { lang === "en" ?  <p className="news-date"><Link to={`/${lang}/vacancies`}  className='btn btn-custom btn-lg' >{text ? text.Vacancies.back : ""}</Link> <Link  to={`/${lang}/resume`} state={{vacancia: jobs.title_en}} className='btn btn-custom btn-lg' >{text ? text.Vacancies.resume : ""}</Link></p> : "" }
                                    { lang === "az" ?  <p className="news-date"><Link to={`/${lang}/vacancies`}  className='btn btn-custom btn-lg' >{text ? text.Vacancies.back : ""}</Link> <Link  to={`/${lang}/resume`} state={{vacancia: jobs.title_az}} className='btn btn-custom btn-lg' >{text ? text.Vacancies.resume : ""}</Link></p> : "" }
                                    { lang === "al" ?  <p className="news-date"><Link to={`/${lang}/vacancies`}  className='btn btn-custom btn-lg' >{text ? text.Vacancies.back : ""}</Link> <Link  to={`/${lang}/resume`} state={{vacancia: jobs.title_al}} className='btn btn-custom btn-lg' >{text ? text.Vacancies.resume : ""}</Link></p> : "" }
                                    { lang === "am" ?  <p className="news-date"><Link to={`/${lang}/vacancies`}  className='btn btn-custom btn-lg' >{text ? text.Vacancies.back : ""}</Link> <Link  to={`/${lang}/resume`} state={{vacancia: jobs.title_am}} className='btn btn-custom btn-lg' >{text ? text.Vacancies.resume : ""}</Link></p> : "" }
                                    { lang === "ge" ?  <p className="news-date"><Link to={`/${lang}/vacancies`}  className='btn btn-custom btn-lg' >{text ? text.Vacancies.back : ""}</Link> <Link  to={`/${lang}/resume`} state={{vacancia: jobs.title_ge}} className='btn btn-custom btn-lg' >{text ? text.Vacancies.resume : ""}</Link></p> : "" }
                                    { lang === "kz" ?  <p className="news-date"><Link to={`/${lang}/vacancies`}  className='btn btn-custom btn-lg' >{text ? text.Vacancies.back : ""}</Link> <Link  to={`/${lang}/resume`} state={{vacancia: jobs.title_kz}} className='btn btn-custom btn-lg' >{text ? text.Vacancies.resume : ""}</Link></p> : "" }
                                    { lang === "kg" ?  <p className="news-date"><Link to={`/${lang}/vacancies`}  className='btn btn-custom btn-lg' >{text ? text.Vacancies.back : ""}</Link> <Link  to={`/${lang}/resume`} state={{vacancia: jobs.title_kg}} className='btn btn-custom btn-lg' >{text ? text.Vacancies.resume : ""}</Link></p> : "" }
                                    { lang === "mk" ?  <p className="news-date"><Link to={`/${lang}/vacancies`}  className='btn btn-custom btn-lg' >{text ? text.Vacancies.back : ""}</Link> <Link  to={`/${lang}/resume`} state={{vacancia: jobs.title_mk}} className='btn btn-custom btn-lg' >{text ? text.Vacancies.resume : ""}</Link></p> : "" }
                                    { lang === "md" ?  <p className="news-date"><Link to={`/${lang}/vacancies`}  className='btn btn-custom btn-lg' >{text ? text.Vacancies.back : ""}</Link> <Link  to={`/${lang}/resume`} state={{vacancia: jobs.title_md}} className='btn btn-custom btn-lg' >{text ? text.Vacancies.resume : ""}</Link></p> : "" }
                                    { lang === "mn" ?  <p className="news-date"><Link to={`/${lang}/vacancies`}  className='btn btn-custom btn-lg' >{text ? text.Vacancies.back : ""}</Link> <Link  to={`/${lang}/resume`} state={{vacancia: jobs.title_mn}} className='btn btn-custom btn-lg' >{text ? text.Vacancies.resume : ""}</Link></p> : "" }
                                    { lang === "si" ?  <p className="news-date"><Link to={`/${lang}/vacancies`}  className='btn btn-custom btn-lg' >{text ? text.Vacancies.back : ""}</Link> <Link  to={`/${lang}/resume`} state={{vacancia: jobs.title_si}} className='btn btn-custom btn-lg' >{text ? text.Vacancies.resume : ""}</Link></p> : "" }
                                    { lang === "tj" ?  <p className="news-date"><Link to={`/${lang}/vacancies`}  className='btn btn-custom btn-lg' >{text ? text.Vacancies.back : ""}</Link> <Link  to={`/${lang}/resume`} state={{vacancia: jobs.title_tj}} className='btn btn-custom btn-lg' >{text ? text.Vacancies.resume : ""}</Link></p> : "" }
                                    { lang === "tr" ?  <p className="news-date"><Link to={`/${lang}/vacancies`}  className='btn btn-custom btn-lg' >{text ? text.Vacancies.back : ""}</Link> <Link  to={`/${lang}/resume`} state={{vacancia: jobs.title_tr}} className='btn btn-custom btn-lg' >{text ? text.Vacancies.resume : ""}</Link></p> : "" }
                                    { lang === "tm" ?  <p className="news-date"><Link to={`/${lang}/vacancies`}  className='btn btn-custom btn-lg' >{text ? text.Vacancies.back : ""}</Link> <Link  to={`/${lang}/resume`} state={{vacancia: jobs.title_tm}} className='btn btn-custom btn-lg' >{text ? text.Vacancies.resume : ""}</Link></p> : "" }
                                    { lang === "uz" ?  <p className="news-date"><Link to={`/${lang}/vacancies`}  className='btn btn-custom btn-lg' >{text ? text.Vacancies.back : ""}</Link> <Link  to={`/${lang}/resume`} state={{vacancia: jobs.title_uz}} className='btn btn-custom btn-lg' >{text ? text.Vacancies.resume : ""}</Link></p> : "" }
                                    { lang === "hr" ?  <p className="news-date"><Link to={`/${lang}/vacancies`}  className='btn btn-custom btn-lg' >{text ? text.Vacancies.back : ""}</Link> <Link  to={`/${lang}/resume`} state={{vacancia: jobs.title_hr}} className='btn btn-custom btn-lg' >{text ? text.Vacancies.resume : ""}</Link></p> : "" }
                                    { lang === "rs" ?  <p className="news-date"><Link to={`/${lang}/vacancies`}  className='btn btn-custom btn-lg' >{text ? text.Vacancies.back : ""}</Link> <Link  to={`/${lang}/resume`} state={{vacancia: jobs.title_rs}} className='btn btn-custom btn-lg' >{text ? text.Vacancies.resume : ""}</Link></p> : "" }
                                    { lang === "bg" ?  <p className="news-date"><Link to={`/${lang}/vacancies`}  className='btn btn-custom btn-lg' >{text ? text.Vacancies.back : ""}</Link> <Link  to={`/${lang}/resume`} state={{vacancia: jobs.title_bg}} className='btn btn-custom btn-lg' >{text ? text.Vacancies.resume : ""}</Link></p> : "" }

                            </div>
                        </div>
                        <div className="news-body">
                            <div className="container">
                                { lang === "ua" ?  <p className="news-text">{jobs.description_ua.length ? <div dangerouslySetInnerHTML={{__html: jobs.description_ua}}/> : text.Vacancies.notInfo}</p> : ""}
                                { lang === "ru" ?  <p className="news-text">{jobs.description_ru.length ? <div dangerouslySetInnerHTML={{__html: jobs.description_ru}}/> : text.Vacancies.notInfo}</p> : ""}
                                { lang === "en" ?  <p className="news-text">{jobs.description_en.length ? <div dangerouslySetInnerHTML={{__html: jobs.description_en}}/> : text.Vacancies.notInfo}</p> : ""}
                                { lang === "az" ?  <p className="news-text">{jobs.description_az.length ? <div dangerouslySetInnerHTML={{__html: jobs.description_az}}/> : text.Vacancies.notInfo}</p> : ""}
                                { lang === "al" ?  <p className="news-text">{jobs.description_al.length ? <div dangerouslySetInnerHTML={{__html: jobs.description_al}}/> : text.Vacancies.notInfo}</p> : ""}
                                { lang === "am" ?  <p className="news-text">{jobs.description_am.length ? <div dangerouslySetInnerHTML={{__html: jobs.description_am}}/> : text.Vacancies.notInfo}</p> : ""}
                                { lang === "ge" ?  <p className="news-text">{jobs.description_ge.length ? <div dangerouslySetInnerHTML={{__html: jobs.description_ge}}/> : text.Vacancies.notInfo}</p> : ""}
                                { lang === "kz" ?  <p className="news-text">{jobs.description_kz.length ? <div dangerouslySetInnerHTML={{__html: jobs.description_kz}}/> : text.Vacancies.notInfo}</p> : ""}
                                { lang === "kg" ?  <p className="news-text">{jobs.description_kg.length ? <div dangerouslySetInnerHTML={{__html: jobs.description_kg}}/> : text.Vacancies.notInfo}</p> : ""}
                                { lang === "mk" ?  <p className="news-text">{jobs.description_mk.length ? <div dangerouslySetInnerHTML={{__html: jobs.description_mk}}/> : text.Vacancies.notInfo}</p> : ""}
                                { lang === "md" ?  <p className="news-text">{jobs.description_md.length ? <div dangerouslySetInnerHTML={{__html: jobs.description_md}}/> : text.Vacancies.notInfo}</p> : ""}
                                { lang === "mn" ?  <p className="news-text">{jobs.description_mn.length ? <div dangerouslySetInnerHTML={{__html: jobs.description_mn}}/> : text.Vacancies.notInfo}</p> : ""}
                                { lang === "si" ?  <p className="news-text">{jobs.description_si.length ? <div dangerouslySetInnerHTML={{__html: jobs.description_si}}/> : text.Vacancies.notInfo}</p> : ""}
                                { lang === "tj" ?  <p className="news-text">{jobs.description_tj.length ? <div dangerouslySetInnerHTML={{__html: jobs.description_tj}}/> : text.Vacancies.notInfo}</p> : ""}
                                { lang === "tr" ?  <p className="news-text">{jobs.description_tr.length ? <div dangerouslySetInnerHTML={{__html: jobs.description_tr}}/> : text.Vacancies.notInfo}</p> : ""}
                                { lang === "tm" ?  <p className="news-text">{jobs.description_tm.length ? <div dangerouslySetInnerHTML={{__html: jobs.description_tm}}/> : text.Vacancies.notInfo}</p> : ""}
                                { lang === "uz" ?  <p className="news-text">{jobs.description_uz.length ? <div dangerouslySetInnerHTML={{__html: jobs.description_uz}}/> : text.Vacancies.notInfo}</p> : ""}
                                { lang === "hr" ?  <p className="news-text">{jobs.description_hr.length ? <div dangerouslySetInnerHTML={{__html: jobs.description_hr}}/> : text.Vacancies.notInfo}</p> : ""}
                                { lang === "rs" ?  <p className="news-text">{jobs.description_hr.length ? <div dangerouslySetInnerHTML={{__html: jobs.description_rs}}/> : text.Vacancies.notInfo}</p> : ""}
                                { lang === "bg" ?  <p className="news-text">{jobs.description_hr.length ? <div dangerouslySetInnerHTML={{__html: jobs.description_bg}}/> : text.Vacancies.notInfo}</p> : ""}
                            </div>
                        </div>
                    </div>
                ) : "Loading..."}
                </div>
                <Footer/>
            </div>
        </div>
    );
};
