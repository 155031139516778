import React from "react";
import {Gallery} from "./gallery";


//Фильтр решения по вендору не реализован! Сейчас вывожу все решения.
export const SolutionForVendors = (props) => {

    return (
        <Gallery />
    )
}
