import React from "react";
import {Navigation} from "../navigation.jsx";
import {Footer} from "../Footer";
import {Gallery} from "../gallery";
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import {GetText} from "../../data/lang";

export const SolutionsPage = () => {

    const {lng} = useParams();
    let text = GetText();

    return (
        <div>
            <Helmet htmlAttributes={{ lang : lng }}>
                <title>MUK | {text.Gallery.title}</title>
                <meta name="description" content={text.Gallery.title} />
                <meta name="keywords"  content='мук, muk, solutions, решения' />

            </Helmet>
            <Navigation lng={lng} />
            <div className="vendor-detail menu-indent">
                <div className="vendor-detail_top">
                    <div className="container">
                        <h3>
                        </h3>
                    </div>
                </div>
            </div>
            <Gallery />
            <Footer />
        </div>
    );
};
