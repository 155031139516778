import React, {useContext} from "react";
import {Navigation} from "../navigation.jsx";
import {VendorsForSolution} from "../VendorsForSolution";
import {useParams} from "react-router-dom";
import {StateContext} from "../../context/GlobalState";
import {Footer} from "../Footer";
import {Gallery} from "../gallery";
import {Helmet} from "react-helmet";

export const SolutionDetail = () => {

    // получение данных
    const {solutionLink, lng} = useParams();
    const {solutionListDetail, lang} = useContext(StateContext);

    let solutionDetail = solutionListDetail ? solutionListDetail.filter(solution => solution.link === solutionLink) : solutionListDetail;

    return (
        <div>
            <Navigation lng={lng}/>
            <div>
                {solutionDetail.length > 0 ?
                    <div>
                        <Helmet htmlAttributes={{lang: lang}}>
                            {lang === "ua" ? <title>MUK | {solutionDetail[0].name_ua} </title> : ""}
                            {lang === "ru" ? <title>MUK | {solutionDetail[0].name_ru} </title> : ""}
                            {lang === "en" ? <title>MUK | {solutionDetail[0].name_en} </title> : ""}
                            {lang === "az" ? <title>MUK | {solutionDetail[0].name_az} </title> : ""}
                            {lang === "al" ? <title>MUK | {solutionDetail[0].name_al} </title> : ""}
                            {lang === "am" ? <title>MUK | {solutionDetail[0].name_am} </title> : ""}
                            {lang === "ge" ? <title>MUK | {solutionDetail[0].name_ge} </title> : ""}
                            {lang === "kz" ? <title>MUK | {solutionDetail[0].name_kz} </title> : ""}
                            {lang === "kg" ? <title>MUK | {solutionDetail[0].name_kg} </title> : ""}
                            {lang === "mk" ? <title>MUK | {solutionDetail[0].name_mk} </title> : ""}
                            {lang === "md" ? <title>MUK | {solutionDetail[0].name_md} </title> : ""}
                            {lang === "mn" ? <title>MUK | {solutionDetail[0].name_mn} </title> : ""}
                            {lang === "si" ? <title>MUK | {solutionDetail[0].name_si} </title> : ""}
                            {lang === "tj" ? <title>MUK | {solutionDetail[0].name_tj} </title> : ""}
                            {lang === "tr" ? <title>MUK | {solutionDetail[0].name_tr} </title> : ""}
                            {lang === "tm" ? <title>MUK | {solutionDetail[0].name_tm} </title> : ""}
                            {lang === "uz" ? <title>MUK | {solutionDetail[0].name_uz} </title> : ""}
                            {lang === "hr" ? <title>MUK | {solutionDetail[0].name_hr} </title> : ""}
                            {lang === "rs" ? <title>MUK | {solutionDetail[0].name_rs} </title> : ""}
                            {lang === "bg" ? <title>MUK | {solutionDetail[0].name_bg} </title> : ""}

                            {lang === "ua" ? <meta name="description" content={solutionDetail[0].description_ua.substr(0, 200)}/> : ""}
                            {lang === "ru" ? <meta name="description" content={solutionDetail[0].description_ru.substr(0, 200)}/> : ""}
                            {lang === "en" ? <meta name="description" content={solutionDetail[0].description_en.substr(0, 200)}/> : ""}
                            {lang === "az" ? <meta name="description" content={solutionDetail[0].description_az.substr(0, 200)}/> : ""}
                            {lang === "al" ? <meta name="description" content={solutionDetail[0].description_al.substr(0, 200)}/> : ""}
                            {lang === "am" ? <meta name="description" content={solutionDetail[0].description_am.substr(0, 200)}/> : ""}
                            {lang === "ge" ? <meta name="description" content={solutionDetail[0].description_ge.substr(0, 200)}/> : ""}
                            {lang === "kz" ? <meta name="description" content={solutionDetail[0].description_kz.substr(0, 200)}/> : ""}
                            {lang === "kg" ? <meta name="description" content={solutionDetail[0].description_kg.substr(0, 200)}/> : ""}
                            {lang === "mk" ? <meta name="description" content={solutionDetail[0].description_mk.substr(0, 200)}/> : ""}
                            {lang === "md" ? <meta name="description" content={solutionDetail[0].description_md.substr(0, 200)}/> : ""}
                            {lang === "mn" ? <meta name="description" content={solutionDetail[0].description_mn.substr(0, 200)}/> : ""}
                            {lang === "si" ? <meta name="description" content={solutionDetail[0].description_si.substr(0, 200)}/> : ""}
                            {lang === "tj" ? <meta name="description" content={solutionDetail[0].description_tj.substr(0, 200)}/> : ""}
                            {lang === "tr" ? <meta name="description" content={solutionDetail[0].description_tr.substr(0, 200)}/> : ""}
                            {lang === "tm" ? <meta name="description" content={solutionDetail[0].description_tm.substr(0, 200)}/> : ""}
                            {lang === "uz" ? <meta name="description" content={solutionDetail[0].description_uz.substr(0, 200)}/> : ""}
                            {lang === "hr" ? <meta name="description" content={solutionDetail[0].description_hr.substr(0, 200)}/> : ""}
                            {lang === "rs" ? <meta name="description" content={solutionDetail[0].description_rs.substr(0, 200)}/> : ""}
                            {lang === "bg" ? <meta name="description" content={solutionDetail[0].description_bg.substr(0, 200)}/> : ""}
                            <meta name="keywords" content={solutionDetail[0].name_ua + ', solution, решение, muk'}/>

                        </Helmet>
                        <div className="solution-detail menu-indent">
                            <div className="solution-title">
                                <div className="container">
                                    <h3>
                                        {lang === "ua" ? solutionDetail[0].name_ua : ""}
                                        {lang === "ru" ? solutionDetail[0].name_ru : ""}
                                        {lang === "en" ? solutionDetail[0].name_en : ""}
                                        {lang === "az" ? solutionDetail[0].name_az : ""}
                                        {lang === "al" ? solutionDetail[0].name_al : ""}
                                        {lang === "am" ? solutionDetail[0].name_am : ""}
                                        {lang === "ge" ? solutionDetail[0].name_ge : ""}
                                        {lang === "kz" ? solutionDetail[0].name_kz : ""}
                                        {lang === "kg" ? solutionDetail[0].name_kg : ""}
                                        {lang === "mk" ? solutionDetail[0].name_mk : ""}
                                        {lang === "md" ? solutionDetail[0].name_md : ""}
                                        {lang === "mn" ? solutionDetail[0].name_mn : ""}
                                        {lang === "si" ? solutionDetail[0].name_si : ""}
                                        {lang === "tj" ? solutionDetail[0].name_tj : ""}
                                        {lang === "tr" ? solutionDetail[0].name_tr : ""}
                                        {lang === "tm" ? solutionDetail[0].name_tm : ""}
                                        {lang === "uz" ? solutionDetail[0].name_uz : ""}
                                        {lang === "hr" ? solutionDetail[0].name_hr : ""}
                                        {lang === "rs" ? solutionDetail[0].name_rs : ""}
                                        {lang === "bg" ? solutionDetail[0].name_bg : ""}
                                    </h3>
                                </div>
                            </div>
                            <div className="container">
                                <p className="solution-text">
                                    {lang === "ua" ? <div className="container">
                                        <p className="news-text">{solutionDetail[0].description_ua.length ?
                                            <div dangerouslySetInnerHTML={{__html: solutionDetail[0].description_ua}}/> : ""}</p>
                                    </div> : ""}
                                    {lang === "ru" ? <div className="container">
                                        <p className="news-text">{solutionDetail[0].description_ru.length ?
                                            <div dangerouslySetInnerHTML={{__html: solutionDetail[0].description_ru}}/> : ""}</p>
                                    </div> : ""}
                                    {lang === "en" ? <div className="container">
                                        <p className="news-text">{solutionDetail[0].description_en.length ?
                                            <div dangerouslySetInnerHTML={{__html: solutionDetail[0].description_en}}/> : ""}</p>
                                    </div> : ""}
                                    {lang === "az" ? <div className="container">
                                        <p className="news-text">{solutionDetail[0].description_az.length ?
                                            <div dangerouslySetInnerHTML={{__html: solutionDetail[0].description_az}}/> : ""}</p>
                                    </div> : ""}
                                    {lang === "al" ? <div className="container">
                                        <p className="news-text">{solutionDetail[0].description_al.length ?
                                            <div dangerouslySetInnerHTML={{__html: solutionDetail[0].description_al}}/> : ""}</p>
                                    </div> : ""}
                                    {lang === "am" ? <div className="container">
                                        <p className="news-text">{solutionDetail[0].description_am.length ?
                                            <div dangerouslySetInnerHTML={{__html: solutionDetail[0].description_am}}/> : ""}</p>
                                    </div> : ""}
                                    {lang === "ge" ? <div className="container">
                                        <p className="news-text">{solutionDetail[0].description_ge.length ?
                                            <div dangerouslySetInnerHTML={{__html: solutionDetail[0].description_ge}}/> : ""}</p>
                                    </div> : ""}
                                    {lang === "kz" ? <div className="container">
                                        <p className="news-text">{solutionDetail[0].description_kz.length ?
                                            <div dangerouslySetInnerHTML={{__html: solutionDetail[0].description_kz}}/> : ""}</p>
                                    </div> : ""}
                                    {lang === "kg" ? <div className="container">
                                        <p className="news-text">{solutionDetail[0].description_kg.length ?
                                            <div dangerouslySetInnerHTML={{__html: solutionDetail[0].description_kg}}/> : ""}</p>
                                    </div> : ""}
                                    {lang === "mk" ? <div className="container">
                                        <p className="news-text">{solutionDetail[0].description_mk.length ?
                                            <div dangerouslySetInnerHTML={{__html: solutionDetail[0].description_mk}}/> : ""}</p>
                                    </div> : ""}
                                    {lang === "md" ? <div className="container">
                                        <p className="news-text">{solutionDetail[0].description_md.length ?
                                            <div dangerouslySetInnerHTML={{__html: solutionDetail[0].description_md}}/> : ""}</p>
                                    </div> : ""}
                                    {lang === "mn" ? <div className="container">
                                        <p className="news-text">{solutionDetail[0].description_mn.length ?
                                            <div dangerouslySetInnerHTML={{__html: solutionDetail[0].description_mn}}/> : ""}</p>
                                    </div> : ""}
                                    {lang === "si" ? <div className="container">
                                        <p className="news-text">{solutionDetail[0].description_si.length ?
                                            <div dangerouslySetInnerHTML={{__html: solutionDetail[0].description_si}}/> : ""}</p>
                                    </div> : ""}
                                    {lang === "tj" ? <div className="container">
                                        <p className="news-text">{solutionDetail[0].description_tj.length ?
                                            <div dangerouslySetInnerHTML={{__html: solutionDetail[0].description_tj}}/> : ""}</p>
                                    </div> : ""}
                                    {lang === "tr" ? <div className="container">
                                        <p className="news-text">{solutionDetail[0].description_tr.length ?
                                            <div dangerouslySetInnerHTML={{__html: solutionDetail[0].description_tr}}/> : ""}</p>
                                    </div> : ""}
                                    {lang === "tm" ? <div className="container">
                                        <p className="news-text">{solutionDetail[0].description_tm.length ?
                                            <div dangerouslySetInnerHTML={{__html: solutionDetail[0].description_tm}}/> : ""}</p>
                                    </div> : ""}
                                    {lang === "uz" ? <div className="container">
                                        <p className="news-text">{solutionDetail[0].description_uz.length ?
                                            <div dangerouslySetInnerHTML={{__html: solutionDetail[0].description_uz}}/> : ""}</p>
                                    </div> : ""}
                                    {lang === "hr" ? <div className="container">
                                        <p className="news-text">{solutionDetail[0].description_hr.length ?
                                            <div dangerouslySetInnerHTML={{__html: solutionDetail[0].description_hr}}/> : ""}</p>
                                    </div> : ""}
                                    {lang === "rs" ? <div className="container">
                                        <p className="news-text">{solutionDetail[0].description_rs.length ?
                                            <div dangerouslySetInnerHTML={{__html: solutionDetail[0].description_rs}}/> : ""}</p>
                                    </div> : ""}
                                    {lang === "bg" ? <div className="container">
                                        <p className="news-text">{solutionDetail[0].description_bg.length ?
                                            <div dangerouslySetInnerHTML={{__html: solutionDetail[0].description_bg}}/> : ""}</p>
                                    </div> : ""}
                                </p>
                            </div>
                        </div>

                        <VendorsForSolution solutionId={solutionDetail[0].id}/>
                    </div>
                    : "Loading..."}
            </div>
            <Gallery/>
            <Footer/>
        </div>
    );
};
