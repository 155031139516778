import React, {useContext, useState} from "react";
import axios from "axios";
import {Footer} from "./Footer";
import Swal from 'sweetalert2'
import {GetText} from "../data/lang";
import ReCAPTCHA from "react-google-recaptcha";
import {StateContext} from "../context/GlobalState";


export const Contact = () => {

    let captcha = 0;

    function onChange(value) {
        //  console.log("Captcha value:", value);
        console.log(value.length);

        if(value){
            captcha = value.length;
        }

    }

    const {lang} = useContext(StateContext);

    //урл
    let httpUrl = window.location.href.substr(0,5);
    let httpUrlPost = "";
    if(httpUrl !== "https"){ httpUrlPost = "http://muk.group/backend/api/feedback/Feedback.php" }
    else{ httpUrlPost = "https://muk.group/backend/api/feedback/Feedback.php" }



    const [name, setName] = useState('');
    const [tel, setTel] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleNameInput = (e) => { setName(e.target.value) };
    const handleTelInput = (e) => { setTel(e.target.value) };
    const handleEmailInput = (e) => { setEmail(e.target.value) };
    const handleMessageInput = (e) => { setMessage(e.target.value) };

    const resetInputField = () => {
        setName("");
        setTel("");
        setEmail("");
        setMessage("");
    };

    const newQuestion = {
        name: name,
        tel: tel,
        email: email,
        message: message
    }


    const checkFeedbackForm = (newQuestion) => {

        if(captcha < 1){

            if(lang === "ua"){
                Swal.fire({
                    icon: 'error',
                    title: 'Капча не заповнена!',
                    text: 'Пройдіть капчу'
                })
            }

            if(lang === "ru"){
                Swal.fire({
                    icon: 'error',
                    title: 'Капча не заполнена!',
                    text: 'Пройдите капчу'
                })
            }

            if(lang === "en"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "az"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "al"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "am"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "ge"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "kz"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "kg"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "mk"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "md"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "mn"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "si"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "tj"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "tr"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "tm"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "uz"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "hr"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "rs"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "bg"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }



        }else {

            // console.log(newQuestion.name);
            if (newQuestion.name.length && newQuestion.tel.length && newQuestion.email.length && newQuestion.message.length) {
                if (sendQuestionRequest(newQuestion)) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Ok!',
                        text: 'Message sent!'
                    })

                    resetInputField();

                } else {

                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        footer: '<a href="">Why do I have this issue?</a>'
                    })
                }

            } else {

                Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text: 'Fields not filled!'
                })
            }
        }

    }


    const sendQuestionRequest = async (newQuestion) => {




        try {
            const resp = await axios.post(httpUrlPost, newQuestion);

            console.log(newQuestion);


            if (resp === false) {
                return false
            } else {
                return true
            }

        } catch (error) {
            console.log(Object.keys(error), error.message)
            return false
        }
    }

    let text = GetText();

    return (
        <div>
          <div id='contact'>
                <div className='container'>
                    <div className='col-md-6'>
                        <div className='row'>
                            <div className='section-title'>
                                <h2>{text ? text.Contact.question : ""}</h2>
                                <p>
                                    {text ? text.Contact.description : ""}
                                </p>
                            </div>


                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            id='name'
                                            name='name'
                                            className='form-control'
                                            placeholder={text ? text.Contact.nm : ""}
                                            required
                                            onChange={handleNameInput}
                                            value={name}
                                        />
                                        <p className='help-block text-danger'></p>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            id='phone'
                                            name='phone'
                                            className='form-control'
                                            placeholder={text ? text.Contact.ph : ""}
                                            required
                                            onChange={handleTelInput}
                                            value={tel}
                                        />
                                        <p className='help-block text-danger'></p>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <input
                                            type='email'
                                            id='email'
                                            name='email'
                                            className='form-control'
                                            placeholder={text ? text.Contact.em : ""}
                                            required
                                            onChange={handleEmailInput}
                                            value={email}
                                        />
                                        <p className='help-block text-danger'></p>
                                    </div>
                                </div>
                            </div>
                            <div className='form-group'>
                  <textarea
                      name='message'
                      id='message'
                      className='form-control'
                      rows='4'
                      placeholder={text ? text.Contact.ms : ""}
                      required
                      onChange={handleMessageInput}
                      value={message}
                  ></textarea>
                                <p className='help-block text-danger'></p>
                            </div>
                            <div><ReCAPTCHA
                                sitekey="6Lf9bBkgAAAAAFcwDhAnIx9_hmXg2_9Q1SDXHgYp"
                                onChange={onChange}
                            /></div>
                            <div id='success'></div>
                            <button onClick={() => checkFeedbackForm(newQuestion)}
                                    className='btn btn-custom btn-lg'>{text ? text.Contact.send : ""}
                            </button>

                        </div>
                    </div>
                   {/* <div className='col-md-5 col-md-offset-1 contact-info'>
                        <div className='contact-item'>
                            <h3>{text ? text.Contact.contactInfo : ""}</h3>
                            <p>
                <span>
                  <i className='fa fa-map-marker'></i> {text ? text.Contact.ad : ""}
                </span>
                                {text.Contact ? text.Contact.address : 'loading'}
                            </p>
                        </div>
                        <div className='contact-item'>
                            <p>
                <span>
                  <i className='fa fa-phone'></i> {text ? text.Contact.ph : ""}
                </span>{' '}
                                {text.Contact ? text.Contact.phone : 'loading'}
                            </p>
                        </div>
                        <div className='contact-item'>
                            <p>
                <span>
                  <i className='fa fa-envelope-o'></i> {text ? text.Contact.em : ""}
                </span>{' '}
                                {text.Contact ? text.Contact.email : 'loading'}
                            </p>
                        </div>
                    </div>*/}
                    <div className='col-md-12'>
                        <div className='row'>
                            <div className='social'>
                                <ul>
                                    <li>
                                        <a target="_blank" rel="noreferrer" title="facebook" href={text.Contact ? text.Contact.facebook : '/'}>
                                            <i className='fa fa-facebook'></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" rel="noreferrer" title="twitter" href={text.Contact ? text.Contact.twitter : '/'}>
                                            <i className='fa fa-twitter'></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" rel="noreferrer" title="youtube" href={text.Contact ? text.Contact.youtube : '/'}>
                                            <i className='fa fa-youtube'></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
