import React from "react";
import {Navigation} from "../navigation.jsx";
import {useLocation, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import {GetText} from "../../data/lang";
import {ContactResume} from "../contactResume";


export const ResumePage = () => {

    const {lng} = useParams();
    let text = GetText();
    const location = useLocation();
    let vacancia = " ";

    if(location.state){
        vacancia = location.state.vacancia;
    }

    //console.log(vacancia);

    return (
        <div>
            <Helmet htmlAttributes={{ lang : lng }}>
                <title>MUK | {text.Resume.title}</title>
                <meta name="description" content={text.Resume.title} />
                <meta name="keywords"  content='мук, muk, distribution, дистрибьюция' />
            </Helmet>
            <Navigation lng={lng}/>
            <div className="solution-detail menu-indent">
                <div className="solution-title">

                </div>
                {/*<div className="container">
                    <br/><br/>
                </div>*/}
            </div>
            { vacancia ? <ContactResume props={{vacancia}} /> : <ContactResume props={{vacancia: ""}} />  }

        </div>
    );
};
