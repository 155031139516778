import React from "react";
import { SwiperSlider } from './slider.jsx';
import {GetText} from "../data/lang";

export const About = () => {

  let text = GetText();

  return (
      <div id="about">
        <div className="container">
          <div className="row wrap-position">
            <div className="col-xs-12 col-md-6">
              <SwiperSlider />
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2>{text ? text.About.title : ""}</h2>
                <p>{text.About ? text.About.paragraph : "Loading..."}</p>

                <h3>{text ? text.About.advantages : ""}</h3>
                <div className="list-style">
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {text.About
                          ? text.About.Why.map((d, i) => (
                              <li key={`${d}-${i}`}>{d}</li>
                          ))
                          : "Loading..."}
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {text.About
                          ? text.About.Why2.map((d, i) => (
                              <li key={`${d}-${i}`}> {d}</li>
                          ))
                          : "Loading..."}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};
