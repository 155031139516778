import React, {useContext, useState} from "react";
import axios from "axios";
import {Footer} from "./Footer";
import Swal from 'sweetalert2'
import {GetText} from "../data/lang";
import {StateContext} from "../context/GlobalState";
import ReCAPTCHA from "react-google-recaptcha";



export const ContactPartner = () => {

    let captcha = 0;

    function onChange(value) {
      //  console.log("Captcha value:", value);
        console.log(value.length);

        if(value){
            captcha = value.length;
        }

    }





    const {lang} = useContext(StateContext);

    //урл
    let httpUrl = window.location.href.substr(0,5);
    let httpUrlPost = "";
    if(httpUrl !== "https"){ httpUrlPost = "http://muk.group/backend/api/partner/Partner.php" }
    else{ httpUrlPost = "https://muk.group/backend/api/partner/Partner.php" }

    const [name, setName] = useState('');
    const [tel, setTel] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [sitecompany, setSiteCompany] = useState('');
    const [direction, setDirection] = useState('');
    const [foundout, setFoundout] = useState('');

    const handleNameInput = (e) => { setName(e.target.value) };
    const handleTelInput = (e) => { setTel(e.target.value) };
    const handleEmailInput = (e) => { setEmail(e.target.value) };
    const handleCompanyInput = (e) => { setCompany(e.target.value) };
    const handleSiteCompanyInput = (e) => { setSiteCompany(e.target.value) };
    const handleDirectionInput = (e) => { setDirection(e.target.value) };
    const handleFoundOutInput = (e) => { setFoundout(e.target.value) };

    const resetInputField = () => {
        setName("");
        setTel("");
        setEmail("");
        setCompany("");
        setSiteCompany("");
        setDirection("");
        setFoundout("");
    };

    const newPartner = {
        name: name,
        tel: tel,
        email: email,
        company: company,
        sitecompany: sitecompany,
        direction: direction,
        foundout: foundout,

    }


    const checkFeedbackForm = (newPartner) => {
        if(captcha < 1){

            if(lang === "ua"){
                Swal.fire({
                    icon: 'error',
                    title: 'Капча не заповнена!',
                    text: 'Пройдіть капчу'
                })
            }

            if(lang === "ru"){
                Swal.fire({
                    icon: 'error',
                    title: 'Капча не заполнена!',
                    text: 'Пройдите капчу'
                })
            }

            if(lang === "en"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "az"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "al"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "am"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "ge"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "kz"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "kg"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "mk"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "md"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "mn"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "si"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "tj"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "tr"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "tm"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "uz"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "hr"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "rs"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "bg"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }



        }else {

            if (newPartner.name.length && newPartner.tel.length && newPartner.email.length) {
                if (sendQuestionRequest(newPartner)) {

                    if(lang === "ua"){
                        Swal.fire({
                            icon: 'success',
                            title: 'OК!',
                            text: 'Дякую, ми зв\'яжемося з вами найближчим часом.'
                        })
                    }

                    if(lang === "ru"){
                        Swal.fire({
                            icon: 'success',
                            title: 'OК!',
                            text: 'Спасибо, мы свяжемся с вами в ближайшее время.'
                        })
                    }

                    if(lang === "en"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }

                    if(lang === "az"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }

                    if(lang === "al"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }


                    if(lang === "am"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }


                    if(lang === "ge"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }


                    if(lang === "kz"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }


                    if(lang === "kg"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }

                    if(lang === "mk"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }

                    if(lang === "md"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }


                    if(lang === "mn"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }


                    if(lang === "si"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }


                    if(lang === "tj"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }

                    if(lang === "tr"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }

                    if(lang === "tm"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }


                    if(lang === "uz"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }

                    if(lang === "hr"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }

                    if(lang === "rs"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }

                    if(lang === "bg"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }






                    resetInputField();

                } else {

                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        footer: '<a href="">Why do I have this issue?</a>'
                    })
                }

            } else {

                if(lang === "ua"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Ой...',
                        text: 'Не всі поля заповнені!'
                    })
                }

                if(lang === "ru"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Упс...',
                        text: 'Не все поля заполнены!'
                    })
                }

                if(lang === "en"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "az"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "al"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "am"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "ge"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "kz"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "kg"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "mk"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "md"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "mn"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "si"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }
                if(lang === "tj"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "tr"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "tm"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "uz"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "hr"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "rs"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "bg"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }



            }

        }



    }


    const sendQuestionRequest = async (newPartner) => {


        try {
            const resp = await axios.post(httpUrlPost, newPartner);
            console.log(newPartner);
           // console.log(httpUrl);

            if (resp === false) {
                return false
            } else {
                return true
            }

        } catch (error) {
            console.log(Object.keys(error), error.message)
            return false
        }
    }

    let text = GetText();


    return (
        <div>
            <div id='contact'>
                <div className='container'>
                    <div className='col-md-8'>
                        <div className='row'>
                            <div className='section-title'>
                                <h2>{text ? text.Partner.title : ""}</h2>
                                <p>
                                    {text ? text.Partner.description : ""}
                                </p>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='form-group'>

                                        <input
                                            type='text'
                                            id='name'
                                            name='name'
                                            className='form-control'
                                            placeholder={text ? text.Partner.nm : ""}
                                            required
                                            onChange={handleNameInput}
                                            value={name}
                                        />
                                        <p className='help-block text-danger'></p>
                                    </div>
                                </div>


                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            id='phone'
                                            name='phone'
                                            className='form-control'
                                            placeholder={text ? text.Partner.ph : ""}
                                            required
                                            onChange={handleTelInput}
                                            value={tel}
                                        />
                                        <p className='help-block text-danger'></p>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <input
                                            type='email'
                                            id='email'
                                            name='email'
                                            className='form-control'
                                            placeholder={text ? text.Partner.em : ""}
                                            required
                                            onChange={handleEmailInput}
                                            value={email}
                                        />
                                        <p className='help-block text-danger'></p>
                                    </div>
                                </div>

                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            id='company'
                                            name='company'
                                            className='form-control'
                                            placeholder={text ? text.Partner.com : ""}
                                            required
                                            onChange={handleCompanyInput}
                                            value={company}
                                        />
                                        <p className='help-block text-danger'></p>
                                    </div>
                                </div>

                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            id='sitecompany'
                                            name='sitecompany'
                                            className='form-control'
                                            placeholder={text ? text.Partner.sitecom : ""}
                                            required
                                            onChange={handleSiteCompanyInput}
                                            value={sitecompany}
                                        />
                                        <p className='help-block text-danger'></p>
                                    </div>
                                </div>

                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            id='direction'
                                            name='direction'
                                            className='form-control'
                                            placeholder={text ? text.Partner.dir : ""}
                                            required
                                            onChange={handleDirectionInput}
                                            value={direction}
                                        />
                                        <p className='help-block text-danger'></p>
                                    </div>
                                </div>

                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            id='found_out'
                                            name='found_out'
                                            className='form-control'
                                            placeholder={text ? text.Partner.found : ""}
                                            required
                                            onChange={handleFoundOutInput}
                                            value={foundout}
                                        />
                                        <p className='help-block text-danger'></p>
                                    </div>

                                </div>

                            </div>
                            <div><ReCAPTCHA
                                sitekey="6Lf9bBkgAAAAAFcwDhAnIx9_hmXg2_9Q1SDXHgYp"
                                onChange={onChange}
                            /></div>
                            <div id='success'></div>

                                <button onClick={() => checkFeedbackForm(newPartner)}
                                        className='btn btn-custom btn-lg'>{text ? text.Partner.send : ""}
                                </button>

                           </div>
                    </div>

                    <div className='col-md-12'>
                        <div className='row'>
                            <div className='social'>
                                <ul>
                                    <li>
                                        <a target="_blank" rel="noreferrer" title="facebook" href={text.Partner ? text.Partner.facebook : '/'}>
                                            <i className='fa fa-facebook'></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" rel="noreferrer" title="twitter" href={text.Partner ? text.Partner.twitter : '/'}>
                                            <i className='fa fa-twitter'></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" rel="noreferrer" title="youtube" href={text.Partner ? text.Partner.youtube : '/'}>
                                            <i className='fa fa-youtube'></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    )
}
