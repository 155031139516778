import React, {useContext, useState} from "react";
import axios from "axios";
import {Footer} from "./Footer";
import Swal from 'sweetalert2'
import {GetText} from "../data/lang";
import {StateContext} from "../context/GlobalState";
import ReCAPTCHA from "react-google-recaptcha";



export const ContactResume = (props) => {

    let captcha = 0;

    function onChange(value) {
      //  console.log("Captcha value:", value);
      //  console.log(value.length);

        if(value){
            captcha = value.length;
        }

    }


    let vacancia = '';
    if(props.props.vacancia !== ' '){
        vacancia = props.props.vacancia;
    }


    const {lang} = useContext(StateContext);

    //урл
    let httpUrl = window.location.href.substr(0,5);
    let httpUrlPost = "";
    if(httpUrl !== "https"){ httpUrlPost = "http://muk.group/backend/api/resume/Resume.php" }
    else{ httpUrlPost = "https://muk.group/backend/api/resume/Resume.php" }

    const [vac, setVac] = useState(vacancia);
    const [name, setName] = useState('');
    const [tel, setTel] = useState('');
    const [email, setEmail] = useState('');
    const [filename, setFileName] = useState('');
    const [note, setNote] = useState('');
    const [file_r, setFile_r] = useState('');



    const handleVacInput = (e) => { setVac(e.target.value) };
    const handleNameInput = (e) => { setName(e.target.value) };
    const handleTelInput = (e) => { setTel(e.target.value) };
    const handleEmailInput = (e) => { setEmail(e.target.value) };
    const handleNoteInput = (e) => { setNote(e.target.value) };

    let formData = new FormData();



    const handleFileInput = (e) => {


        let formatfile = e.target.files[0].name.match(/\.([^.]+)$|$/)[1];
        //console.log(formatfile);
        //console.log(e.target.value);

        if(formatfile === "pdf" || formatfile === "doc" || formatfile === "docx"){
            // console.log(e.target.files[0]);
            // formData.append("file", e.target.files[0]);
            setFileName(e.target.files[0].name);
            setFile_r(e.target.files[0]);
        }
        else{

            e.target.value = '';

            Swal.fire({
                icon: 'error',
                title: 'Wrong file format',
                text: 'Please, choose another file'
            })

        }

    };



    const resetInputField = () => {
        setVac("");
        setName("");
        setTel("");
        setEmail("");
        setFileName("");
        setNote("");
        setFile_r("");
    };

    const newResume = {
        vac: vac,
        name: name,
        tel: tel,
        email: email,
        filename: filename,
        note: note,
        file_r: file_r
    }

    const sendQuestionRequest = async (newResume) => {

        //console.log(newResume);

        formData.append("vac", newResume.vac);
        formData.append("name", newResume.name);
        formData.append("tel", newResume.tel);
        formData.append("email", newResume.email);
        formData.append("filename", newResume.filename);
        formData.append("note", newResume.note);
        formData.append("file", newResume.file_r);

        console.log(formData);

        try {


            const resp = await axios.post(httpUrlPost, formData, {headers: { "Content-Type": "multipart/form-data" }});

           // console.log(resp);

            if (resp === false) {
                return false
            } else {
                return true
            }

        } catch (error) {
            console.log(Object.keys(error), error.message)
            return false
        }
    }



    const checkFeedbackForm = (newResume) => {
        if(captcha < 1){

            if(lang === "ua"){
                Swal.fire({
                    icon: 'error',
                    title: 'Капча не заповнена!',
                    text: 'Пройдіть капчу'
                })
            }

            if(lang === "ru"){
                Swal.fire({
                    icon: 'error',
                    title: 'Капча не заполнена!',
                    text: 'Пройдите капчу'
                })
            }

            if(lang === "en"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "az"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "al"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "am"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "ge"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "kz"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "kg"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "mk"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "md"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "mn"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "si"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "tj"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "tr"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "tm"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "uz"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "hr"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }

            if(lang === "rs"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }


            if(lang === "bg"){
                Swal.fire({
                    icon: 'error',
                    title: 'The captcha is not filled!',
                    text: 'Pass the captcha'
                })
            }



        }else {

            if (newResume.name.length && newResume.tel.length && newResume.email.length) {
                if (sendQuestionRequest(newResume)) {

                    if(lang === "ua"){
                        Swal.fire({
                            icon: 'success',
                            title: 'OК!',
                            text: 'Дякую, ми зв\'яжемося з вами найближчим часом.'
                        })
                    }

                    if(lang === "ru"){
                        Swal.fire({
                            icon: 'success',
                            title: 'OК!',
                            text: 'Спасибо, мы свяжемся с вами в ближайшее время.'
                        })
                    }

                    if(lang === "en"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }

                    if(lang === "az"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }

                    if(lang === "al"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }


                    if(lang === "am"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }


                    if(lang === "ge"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }


                    if(lang === "kz"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }


                    if(lang === "kg"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }

                    if(lang === "mk"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }

                    if(lang === "md"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }


                    if(lang === "mn"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }


                    if(lang === "si"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }


                    if(lang === "tj"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }

                    if(lang === "tr"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }

                    if(lang === "tm"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }


                    if(lang === "uz"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }

                    if(lang === "hr"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }


                    if(lang === "rs"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }

                    if(lang === "bg"){
                        Swal.fire({
                            icon: 'success',
                            title: 'Ok!',
                            text: 'Thank you, we will contact you shortly.'
                        })
                    }




                    resetInputField();

                } else {

                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        footer: '<a href="">Why do I have this issue?</a>'
                    })
                }

            } else {

                if(lang === "ua"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Ой...',
                        text: 'Не всі поля заповнені!'
                    })
                }

                if(lang === "ru"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Упс...',
                        text: 'Не все поля заполнены!'
                    })
                }

                if(lang === "en"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "az"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "al"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "am"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "ge"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "kz"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "kg"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "mk"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "md"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "mn"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "si"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }
                if(lang === "tj"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "tr"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "tm"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "uz"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "hr"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "rs"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }

                if(lang === "bg"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Fields not filled!'
                    })
                }



            }

        }



    }



    let text = GetText();


    return (
        <div>
            <div id='contact'>
                <div className='container'>
                    <div className='col-md-8'>
                        <div className='row'>
                            <div className='section-title'>
                                <h2>{text ? text.Resume.title : ""}</h2>
                                <p>
                                    {text ? text.Resume.description : ""}
                                </p>
                            </div>

                            <div className='row'>

                                <div className='col-md-12'>
                                    <div className='form-group'>

                                        <input
                                            type='text'
                                            id='vac'
                                            name='vac'
                                            className='form-control'
                                            placeholder={text ? text.Resume.vac : ""}
                                            required
                                            onChange={handleVacInput}
                                            value={vac}
                                        />
                                        <p className='help-block text-danger'></p>
                                    </div>
                                </div>

                                <div className='col-md-12'>
                                    <div className='form-group'>

                                        <input
                                            type='text'
                                            id='name'
                                            name='name'
                                            className='form-control'
                                            placeholder={text ? text.Resume.nm : ""}
                                            required
                                            onChange={handleNameInput}
                                            value={name}
                                        />
                                        <p className='help-block text-danger'></p>
                                    </div>
                                </div>


                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            id='phone'
                                            name='phone'
                                            className='form-control'
                                            placeholder={text ? text.Resume.ph : ""}
                                            required
                                            onChange={handleTelInput}
                                            value={tel}
                                        />
                                        <p className='help-block text-danger'></p>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <input
                                            type='email'
                                            id='email'
                                            name='email'
                                            className='form-control'
                                            placeholder={text ? text.Resume.em : ""}
                                            required
                                            onChange={handleEmailInput}
                                            value={email}
                                        />
                                        <p className='help-block text-danger'></p>
                                    </div>
                                </div>


                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            id='note'
                                            name='note'
                                            className='form-control'
                                            placeholder={text ? text.Resume.note : ""}
                                            required
                                            onChange={handleNoteInput}
                                            value={note}
                                        />
                                        <p className='help-block text-danger'></p>
                                    </div>
                                </div>

                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <label htmlFor="file"><p>{text.Resume.file}:</p></label>
                                        <input
                                            type='file'
                                            id='file'
                                            name='file'
                                            className='form-control'
                                            placeholder={text ? text.Resume.file : ""}
                                            required
                                            onChange={handleFileInput}
                                        />
                                        <p className='help-block text-danger'></p>
                                    </div>
                                </div>


                            </div>
                            <div><ReCAPTCHA
                                sitekey="6Lf9bBkgAAAAAFcwDhAnIx9_hmXg2_9Q1SDXHgYp"
                                onChange={onChange}
                            /></div>
                            <div id='success'></div>

                                <button onClick={() => checkFeedbackForm(newResume)}
                                        className='btn btn-custom btn-lg'>{text ? text.Partner.send : ""}
                                </button>

                           </div>
                    </div>

                    <div className='col-md-12'>
                        <div className='row'>
                            <div className='social'>
                                <ul>
                                    <li>
                                        <a target="_blank" rel="noreferrer" title="facebook" href={text.Partner ? text.Partner.facebook : '/'}>
                                            <i className='fa fa-facebook'></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" rel="noreferrer" title="twitter" href={text.Partner ? text.Partner.twitter : '/'}>
                                            <i className='fa fa-twitter'></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" rel="noreferrer" title="youtube" href={text.Partner ? text.Partner.youtube : '/'}>
                                            <i className='fa fa-youtube'></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    )
}
