import React from "react";
import {Navigation} from "../navigation.jsx";
import {Footer} from "../Footer";
import {Features} from "../features";
import {Services} from "../services";
import {About} from "../about";
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import {GetText} from "../../data/lang";

export const AboutPage = () => {

    const {lng} = useParams();
    let text = GetText();

    return (
        <div>
            <Helmet htmlAttributes={{ lang : lng }}>
                <title>MUK | {text.About.title}</title>
                <meta name="description" content={text.About.paragraph} />
                <meta name="keywords"  content='мук, muk, about, о компании' />
            </Helmet>
            <Navigation lng={lng}/>
            <div className="solution-detail menu-indent">
                <div className="solution-title">
                    <div className="container">
                        <h3>
                            {text.OMuk.title}
                        </h3>
                    </div>
                </div>
                <div className="container">
                    <br/><br/>
                    <p>{text.OMuk.p1}</p>
                    <p>{text.OMuk.p2}</p>
                    <p>{text.OMuk.p3}</p>
                    <h3>{text.OMuk.bp1}</h3>
                    <p>{text.OMuk.p4}</p>
                    <p>{text.OMuk.ul}</p>
                    <p>{text.OMuk.li1}</p>
                    <p>{text.OMuk.li2}</p>
                    <p>{text.OMuk.li3}</p>
                    <p>{text.OMuk.li4}</p>
                    <h3>{text.OMuk.bp2}</h3>
                    <p>{text.OMuk.p5}</p>
                    <h3>{text.OMuk.bp3}</h3>
                    <p>{text.OMuk.p6}</p>
                    <p>{text.OMuk.p7}</p>
                    <p>{text.OMuk.p8}</p>
                    <p>{text.OMuk.p9}</p>
                    <h3>{text.OMuk.bp4}</h3>
                    <p>{text.OMuk.p10}</p>
                    <p>{text.OMuk.p11}</p>
                    <p>{text.OMuk.p12}</p>
                    <h3>{text.OMuk.bp5}</h3>
                    <p>{text.OMuk.p13}</p>
                    <h3>{text.OMuk.bp6}</h3>
                    <p>{text.OMuk.p14}</p>
                    <h3>{text.OMuk.bp7}</h3>
                    <p>{text.OMuk.p15}</p>
                    <h3>{text.OMuk.bp8}</h3>
                    <p>{text.OMuk.p16}</p>
                    <h3>{text.OMuk.bp9}</h3>
                    <p>{text.OMuk.p17}</p>
                    <h3>{text.OMuk.bp10}</h3>
                    <p>{text.OMuk.p18}</p>
                    <h3>{text.OMuk.bp11}</h3>
                    <p>{text.OMuk.p19}</p>
                    <h3>{text.OMuk.bp12}</h3>
                    <p>{text.OMuk.p20}</p>
                    <br/><br/>
                    <h3>{text.OMuk.bp13}</h3>
                    <p>{text.OMuk.p21}</p>
                    <p>{text.OMuk.p22}</p>
                    <p>{text.OMuk.p23}</p>
                    <p>{text.OMuk.p24}</p>
                    <p>{text.OMuk.p25}</p>
                    <p>{text.OMuk.p26}</p>
                    <p>{text.OMuk.p27}</p>
                    <p>{text.OMuk.p28}</p>
                    <br/><br/>

                </div>
            </div>
            <Features />
            <Services />
            <About />
            <Footer/>
        </div>
    );
};
