import React from "react";
import {Navigation} from "../navigation.jsx";
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import {GetText} from "../../data/lang";
import {ContactPartner} from "../contactPartner";

export const PartnerPage = () => {

    const {lng} = useParams();
    let text = GetText();

    return (
        <div>
            <Helmet htmlAttributes={{ lang : lng }}>
                <title>MUK | {text.Partner.title}</title>
                <meta name="description" content={text.Partner.paragraph} />
                <meta name="keywords"  content='мук, muk, distribution, дистрибьюция' />
            </Helmet>
            <Navigation lng={lng}/>
            <div className="solution-detail menu-indent">
                <div className="solution-title">

                </div>
                {/*<div className="container">
                    <br/><br/>
                </div>*/}
            </div>
            <ContactPartner />

        </div>
    );
};
