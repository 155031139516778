import React, {useContext} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, { Navigation, EffectCoverflow } from 'swiper';
import {StateContext} from "../context/GlobalState.jsx";

import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/effect-creative/effect-creative.min.css'

SwiperCore.use([Navigation, EffectCoverflow]);

export const SwiperSlider = (props) => {

    const awards = useContext(StateContext);
    const slide = awards ? awards : "none";
    const awardsList = slide.awards ? slide.awards : "none";

 //   console.log(awardsList);

    const slides = awardsList.map((award) =>
            <SwiperSlide key={`slide-${award.id}`} tag="li">
                <img title={award.name} src={`/img/${award.imgAward}`} alt={award.name} />
            </SwiperSlide>)


    return (
        <React.Fragment>
        <Swiper
            id="main"
            wrapperTag="ul"
            tag="section"
            navigation
            spaceBetween={0}
            slidesPerView={1}
            autoHeight={true}
            loop={true}
            grabCursor={true}
            effect={"coverflow"}
            coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
            }}
        >
                {slides}
        </Swiper>
    </React.Fragment>
    );
};

