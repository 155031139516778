import React, {useContext} from "react";
import {StateContext} from "../context/GlobalState.jsx";
import {Link} from "react-router-dom";
import {GetText} from "../data/lang";


export const VendorsForSolution = (props) => {

    const {vendorList, lang} = useContext(StateContext);

    let vendorsShow = [];

    //Вывод логотипов
    let refreshVendors = (vendorsView) => {

        if (vendorsView.length > 0) {
            // console.log(props.solutionId)
            vendorsView = vendorsView.filter(vend => vend.avaliable_solutions.split(',').includes(props.solutionId));

            vendorsShow = vendorsView.map((vend) => <div key={`vendorsView-${vend.id}`}
                                                         className='col-md-2 col-sm-3 col-xs-6 team'>
                <div className='thumbnail'>{' '}
                    <Link to={`/${lang}/vendor/${vend.link}`} title={vend.name} >
                        <svg className='team-img' viewBox="0 0 145 55" width="145" height="55">
                            <rect x="1" y="1" width="143" height="53" rx="6" ry="6" stroke="rgb(179, 179, 179)" stroke-width="1" fill="none" />
                            <image   href={`/img/vendors/${vend.img}`} x="0" y="0" width="145" height="55" />
                        </svg>
                    </Link>
                    {/*<Link to={`/${lang}/vendor/${vend.link}`} title={vend.name} >
                                    <img src={`/img/vendors/${vend.img}`} title={vend.name} alt={`logo-vendor-${vend.name}`} className='team-img' />
                                </Link>*/}
                </div>
            </div>)
        }

    }

    refreshVendors(vendorList);

    let text = GetText();

    return (
        <div>
            <div id='team' className='text-center'>
                <div className='container'>
                    <div className='col-md-12 section-title'>
                        <h2>{text ? text.VendorsForSolution.title : ""}</h2>
                        <p>

                        </p>
                    </div>
                    <div id='row'>

                        {vendorsShow ? vendorsShow : vendorList.list ? vendorList.list.map((vend) => <div
                            key={`${vend.id}`} className='col-md-2 col-sm-3 col-xs-6 team'>
                            <div className='thumbnail'>
                                {' '}
                                <Link to={`/${lang}/vendor/${vend.link}`} title={vend.name} >
                                    <svg className='team-img' viewBox="0 0 145 55" width="145" height="55">
                                        <rect x="1" y="1" width="143" height="53" rx="6" ry="6" stroke="rgb(179, 179, 179)" stroke-width="1" fill="none" />
                                        <image   href={`/img/vendors/${vend.img}`} x="0" y="0" width="145" height="55" />
                                    </svg>
                                </Link>
                                {/*<Link to={`/${lang}/vendor/${vend.link}`} title={vend.name} >
                                    <img src={`/img/vendors/${vend.img}`} title={vend.name} alt={`logo-vendor-${vend.name}`} className='team-img' />
                                </Link>*/}
                                {/*<div className='caption'> <h4>{vend.name}</h4> </div>*/}
                            </div>
                        </div>) : "Loading..."}
                    </div>
                </div>
            </div>
        </div>
    )
}
