import React, { useContext } from "react";
import { Image } from "./image.jsx";
import { StateContext } from "../context/GlobalState.jsx";
import {Link} from "react-router-dom";
import {GetText} from "../data/lang";

export const Gallery = () => {

    const {solutionList, lang} = useContext(StateContext);

    let text = GetText();

    return (
        <div id='portfolio' className='text-center'>
            <div className='container'>
                <div className='section-title'>
                    <h2>{text ? text.Gallery.title : ""}</h2>
                </div>
                <div className='row'>

                        <div className='portfolio-items same-height'>

                            {solutionList ? solutionList.map((solution, i) => <div key={i} className='col-sm-6 col-md-4 col-lg-4'>
                                {lang === "ua" ? <Link to={`/${lang}/solution/${solution.link}`} title={solution.name_ua} ><Image title={solution.name_ua} largeImage={solution.img} smallImage={solution.img} /><h4>{solution.name_ua}</h4></Link> : ""}
                                {lang === "ru" ? <Link to={`/${lang}/solution/${solution.link}`} title={solution.name_ru} ><Image title={solution.name_ru} largeImage={solution.img} smallImage={solution.img} /><h4>{solution.name_ru}</h4></Link> : ""}
                                {lang === "en" ? <Link to={`/${lang}/solution/${solution.link}`} title={solution.name_en} ><Image title={solution.name_en} largeImage={solution.img} smallImage={solution.img} /><h4>{solution.name_en}</h4></Link> : ""}
                                {lang === "az" ? <Link to={`/${lang}/solution/${solution.link}`} title={solution.name_az} ><Image title={solution.name_az} largeImage={solution.img} smallImage={solution.img} /><h4>{solution.name_az}</h4></Link> : ""}
                                {lang === "al" ? <Link to={`/${lang}/solution/${solution.link}`} title={solution.name_al} ><Image title={solution.name_al} largeImage={solution.img} smallImage={solution.img} /><h4>{solution.name_al}</h4></Link> : ""}
                                {lang === "am" ? <Link to={`/${lang}/solution/${solution.link}`} title={solution.name_am} ><Image title={solution.name_am} largeImage={solution.img} smallImage={solution.img} /><h4>{solution.name_am}</h4></Link> : ""}
                                {lang === "ge" ? <Link to={`/${lang}/solution/${solution.link}`} title={solution.name_ge} ><Image title={solution.name_ge} largeImage={solution.img} smallImage={solution.img} /><h4>{solution.name_ge}</h4></Link> : ""}
                                {lang === "kz" ? <Link to={`/${lang}/solution/${solution.link}`} title={solution.name_kz} ><Image title={solution.name_kz} largeImage={solution.img} smallImage={solution.img} /><h4>{solution.name_kz}</h4></Link> : ""}
                                {lang === "kg" ? <Link to={`/${lang}/solution/${solution.link}`} title={solution.name_kg} ><Image title={solution.name_kg} largeImage={solution.img} smallImage={solution.img} /><h4>{solution.name_kg}</h4></Link> : ""}
                                {lang === "mk" ? <Link to={`/${lang}/solution/${solution.link}`} title={solution.name_mk} ><Image title={solution.name_mk} largeImage={solution.img} smallImage={solution.img} /><h4>{solution.name_mk}</h4></Link> : ""}
                                {lang === "md" ? <Link to={`/${lang}/solution/${solution.link}`} title={solution.name_md} ><Image title={solution.name_md} largeImage={solution.img} smallImage={solution.img} /><h4>{solution.name_md}</h4></Link> : ""}
                                {lang === "mn" ? <Link to={`/${lang}/solution/${solution.link}`} title={solution.name_mn} ><Image title={solution.name_mn} largeImage={solution.img} smallImage={solution.img} /><h4>{solution.name_mn}</h4></Link> : ""}
                                {lang === "si" ? <Link to={`/${lang}/solution/${solution.link}`} title={solution.name_si} ><Image title={solution.name_si} largeImage={solution.img} smallImage={solution.img} /><h4>{solution.name_si}</h4></Link> : ""}
                                {lang === "tj" ? <Link to={`/${lang}/solution/${solution.link}`} title={solution.name_tj} ><Image title={solution.name_tj} largeImage={solution.img} smallImage={solution.img} /><h4>{solution.name_tj}</h4></Link> : ""}
                                {lang === "tr" ? <Link to={`/${lang}/solution/${solution.link}`} title={solution.name_tr} ><Image title={solution.name_tr} largeImage={solution.img} smallImage={solution.img} /><h4>{solution.name_tr}</h4></Link> : ""}
                                {lang === "tm" ? <Link to={`/${lang}/solution/${solution.link}`} title={solution.name_tm} ><Image title={solution.name_tm} largeImage={solution.img} smallImage={solution.img} /><h4>{solution.name_tm}</h4></Link> : ""}
                                {lang === "uz" ? <Link to={`/${lang}/solution/${solution.link}`} title={solution.name_uz} ><Image title={solution.name_uz} largeImage={solution.img} smallImage={solution.img} /><h4>{solution.name_uz}</h4></Link> : ""}
                                {lang === "hr" ? <Link to={`/${lang}/solution/${solution.link}`} title={solution.name_hr} ><Image title={solution.name_hr} largeImage={solution.img} smallImage={solution.img} /><h4>{solution.name_hr}</h4></Link> : ""}
                                {lang === "rs" ? <Link to={`/${lang}/solution/${solution.link}`} title={solution.name_rs} ><Image title={solution.name_rs} largeImage={solution.img} smallImage={solution.img} /><h4>{solution.name_rs}</h4></Link> : ""}
                                {lang === "bg" ? <Link to={`/${lang}/solution/${solution.link}`} title={solution.name_bg} ><Image title={solution.name_bg} largeImage={solution.img} smallImage={solution.img} /><h4>{solution.name_bg}</h4></Link> : ""}
                            </div>) : "Loading..."}

                        </div>

                </div>
            </div>
        </div>
    )
}
