import React from "react";
import {Navigation} from "../navigation.jsx";
import {Footer} from "../Footer";
import {Vendors} from "../Vendors";
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import {GetText} from "../../data/lang";

export const VendorsPage = () => {

    const {lng} = useParams();
    let text = GetText();

    return (
        <div>
            <Helmet htmlAttributes={{ lang : lng }}>
                <title>MUK | {text ? text.Vendors.title : ""}</title>
                <meta name="description" content={text ? text.Vendors.title : ""} />
                <meta name="keywords"  content='country, muk, vendors, компании' />

            </Helmet>
            <Navigation lng={lng}/>
            <div className="vendor-detail menu-indent">
                <div className="vendor-detail_top">
                    <div className="container">
                        <h3>
                        </h3>
                    </div>
                </div>
            </div>
            <Vendors />
            <Footer/>
        </div>
    );
};
