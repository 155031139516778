import React, {useState, useEffect} from "react"

// creating `context`
const StateContext = React.createContext();

function StateProvider({children}) {

    const [lang, setLang] = useState("en"); //выбор языка
    const [geo, setGeo] = useState("UA"); //регион откуда
    const [activeCountryVac, setActiveCountryVac] = useState(""); //для перехода назад в вакансии
    const [jobsList, setJobs] = useState([]);
    const [awards, setAwards] = useState(["0"]);
    const [vendorList, setVendors] = useState([]);
    const [countryList, setCountry] = useState([]);
    const [solutionList, setSolutions] = useState([]);
    const [solutionListDetail, setSolutionsDetail] = useState([]);
    const [newsList, setNews] = useState([]);
    const [newsListStart, setNewsListStart] = useState(0);
    const [newsListEnd, setNewsListEnd] = useState(48);


    const url_vendors_r = "https://muk.group/backend/api/vendors/read.php";
    const url_news_r = "https://muk.group/backend/api/news/read.php";
    const url_jobs_r = "https://muk.group/backend/api/jobs/read.php";
    const url_awards_r = "https://muk.group/backend/api/awards/read.php";
    const url_country_r = "https://muk.group/backend/api/countrys/read.php";
    const url_solutions_r = "https://muk.group/backend/api/solutions/read.php";
    const url_solutionsDetail_r = "https://muk.group/backend/api/solutionsDetail/read.php";
    const url_Geo_r = "https://muk.group/backend/geo/";

    useEffect(() => {
        fetch(url_vendors_r)
            .then(response => response.json()) // get json data from api
            .then(data =>
                setVendors(data.list)
            ) // save data to `state`
    }, [])

    useEffect(() => {
        fetch(url_news_r)
            .then(response => response.json()) // get json data from api
            .then(data =>
                setNews(data.list)
            ) // save data to `state`
    }, [])


    useEffect(() => {
        fetch(url_jobs_r)
            .then(response => response.json()) // get json data from api
            .then(data =>
                setJobs(data.list)
            ) // save data to `state`
    }, [])

    useEffect(() => {
        fetch(url_awards_r)
            .then(response => response.json()) // get json data from api
            .then(data =>
                setAwards(data)
            ) // save data to `state`
    }, [])

    useEffect(() => {
        fetch(url_country_r)
            .then(response => response.json()) // get json data from api
            .then(data =>
                setCountry(data)
            ) // save data to `state`
    }, [])



    useEffect(() => {
        fetch(url_solutions_r)
            .then(response => response.json()) // get json data from api
            .then(data =>
                setSolutions(data)
            ) // save data to `state`
    }, [])

    useEffect(() => {
        fetch(url_solutionsDetail_r)
            .then(response => response.json()) // get json data from api
            .then(data =>
                setSolutionsDetail(data)
            ) // save data to `state`
    }, [])

    useEffect(() => {
        fetch(url_Geo_r)
            .then(response => response.json()) // get json data from api
            .then(data =>
                setGeo(data)
            ) // save data to `state`
    }, [])



    return (
        <StateContext.Provider
            value={{geo, setGeo, lang, activeCountryVac, setActiveCountryVac, setLang, jobsList, awards, vendorList, countryList, solutionList, solutionListDetail, newsList, newsListStart, newsListEnd, setNewsListStart, setNewsListEnd}}>
            {children}
        </StateContext.Provider>
    )
}

export {StateContext, StateProvider}