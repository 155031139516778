import React from "react";

export const Image = ({ title, smallImage }) => {
  return (
    <div className='portfolio-item'>
      <div className='hover-bg'>
        {' '}
          <img  title={title} src={`/img/solutions/${smallImage}`} className='img-responsive img-gallery' alt={smallImage}/>
          {' '}
      </div>
    </div>
  )
}