import React, {useContext, useState} from "react";
import {StateContext} from "../context/GlobalState.jsx";
import {Link} from "react-router-dom";
import {GetText} from "../data/lang";


export const Vendors = () => {
    const {vendorList, solutionList, countryList, lang, geo} = useContext(StateContext);

    const [vendorsShow, setVendorsShow] = useState("");  //массив который отображаем (верстка)
    const [vendorsItems, setVendorsItems] = useState(vendorList);  //массив который отображаем
    const [c_filter, setC_filter] = useState('');  // состояние фильтра страны
    const [s_filter, setS_filter] = useState('');  // состояние фильтра решения

    let vendorModified = []; //временный массив для работы (фильтр по региону и решению)
    let vendorSearchModified = []; //временный массив для работы (поиск по имени)


    // фильтр Азербайджана и Армении
    let countryListMod = countryList;
    if(geo === "az"){ countryListMod = countryList.filter((c) =>  c.iso !== "am" );}
    if(geo === "am"){ countryListMod = countryList.filter((c) =>  c.iso !== "az" );}

    //Костыль для вакансий
    //  countryListMod = countryListMod.filter((c) =>  c.iso !== "bg");
    //  countryListMod = countryListMod.filter((c) =>  c.iso !== "rs");

    //сортировка стран по алфавиту
    if (lang === "ua"){   countryListMod = countryListMod.sort(function(a,b){ return a.name_ua.localeCompare(b.name_ua) }) }
    if (lang === "ru"){   countryListMod = countryListMod.sort(function(a,b){ return a.name_ru.localeCompare(b.name_ru) }) }
    if (lang === "en"){   countryListMod = countryListMod.sort(function(a,b){ return a.name_en.localeCompare(b.name_en) }) }
    if (lang === "az"){   countryListMod = countryListMod.sort(function(a,b){ return a.name_az.localeCompare(b.name_az) }) }
    if (lang === "al"){   countryListMod = countryListMod.sort(function(a,b){ return a.name_al.localeCompare(b.name_al) }) }
    if (lang === "am"){   countryListMod = countryListMod.sort(function(a,b){ return a.name_am.localeCompare(b.name_am) }) }
    if (lang === "ge"){   countryListMod = countryListMod.sort(function(a,b){ return a.name_ge.localeCompare(b.name_ge) }) }
    if (lang === "kz"){   countryListMod = countryListMod.sort(function(a,b){ return a.name_kz.localeCompare(b.name_kz) }) }
    if (lang === "kg"){   countryListMod = countryListMod.sort(function(a,b){ return a.name_kg.localeCompare(b.name_kg) }) }
    if (lang === "mk"){   countryListMod = countryListMod.sort(function(a,b){ return a.name_mk.localeCompare(b.name_mk) }) }
    if (lang === "md"){   countryListMod = countryListMod.sort(function(a,b){ return a.name_md.localeCompare(b.name_md) }) }
    if (lang === "mn"){   countryListMod = countryListMod.sort(function(a,b){ return a.name_mn.localeCompare(b.name_mn) }) }
    if (lang === "si"){   countryListMod = countryListMod.sort(function(a,b){ return a.name_si.localeCompare(b.name_si) }) }
    if (lang === "tj"){   countryListMod = countryListMod.sort(function(a,b){ return a.name_tj.localeCompare(b.name_tj) }) }
    if (lang === "tr"){   countryListMod = countryListMod.sort(function(a,b){ return a.name_tr.localeCompare(b.name_tr) }) }
    if (lang === "tm"){   countryListMod = countryListMod.sort(function(a,b){ return a.name_tm.localeCompare(b.name_tm) }) }
    if (lang === "uz"){   countryListMod = countryListMod.sort(function(a,b){ return a.name_uz.localeCompare(b.name_uz) }) }
    if (lang === "hr"){   countryListMod = countryListMod.sort(function(a,b){ return a.name_hr.localeCompare(b.name_hr) }) }
    if (lang === "rs"){   countryListMod = countryListMod.sort(function(a,b){ return a.name_rs.localeCompare(b.name_rs) }) }
    if (lang === "bg"){   countryListMod = countryListMod.sort(function(a,b){ return a.name_bg.localeCompare(b.name_bg) }) }



    //функция выбора страны
    let selectCountry = (e) => {
        if (e.target.value) {

            setC_filter(e.target.value);

            if(e.target.value && s_filter === "") {
                vendorModified = vendorList.filter(vend => vend.avaliable_counteies.split(',').includes(e.target.value));
            }

            if(e.target.value && s_filter){
                vendorModified =  vendorList.filter(vend => vend.avaliable_counteies.split(',').includes(e.target.value));
                vendorModified =  vendorModified.filter(vend2 => vend2.avaliable_solutions.split(',').includes(s_filter));
            }
        }
        else {
            setC_filter("");

            if(s_filter) {
                vendorModified = vendorList.filter(vend => vend.avaliable_solutions.split(',').includes(s_filter));
            }
            else {
                vendorModified = vendorList;
            }
        }

        //console.log(vendorModified);

        setVendorsItems(vendorModified);
        /*console.log(vendorModified);
        console.log("c_filter: ", e.target.value);
        console.log("s_filter: ", s_filter);
        console.log("_____________");
        console.log(vendorsItems);
        console.log("c_filter: ", c_filter);
        console.log("s_filter: ", s_filter);*/
        refreshVendors(vendorModified);

    }

    //функция выбора решения
    let selectSolution = (e) => {
        if (e.target.value) {

            setS_filter(e.target.value);

            if(e.target.value && c_filter === "") {
                vendorModified = vendorList.filter(vend => vend.avaliable_solutions.split(',').includes(e.target.value));
            }

            if(e.target.value && c_filter){

                vendorModified =  vendorList.filter(vend => vend.avaliable_solutions.split(',').includes(e.target.value));
                vendorModified =  vendorModified.filter(vend2 => vend2.avaliable_counteies.split(',').includes(c_filter));
            }


        }
        else {
            setS_filter("");
            if(c_filter) {
                vendorModified = vendorList.filter(vend => vend.avaliable_counteies.split(',').includes(c_filter));
            }
            else {
                vendorModified = vendorList;
            }
        }

        setVendorsItems(vendorModified);
       /* console.log(vendorModified);
        console.log("c_filter: ", c_filter);
        console.log("s_filter: ", e.target.value);
        console.log("_____________");
        console.log(vendorsItems);
        console.log("c_filter: ", c_filter);
        console.log("s_filter: ", s_filter); */
        refreshVendors(vendorModified);
    }

    //функция поиск по имени
    let vendorsNameSearch = (e) => {

        if (e.target.value) {


            if(vendorsItems.length > 0){
                vendorSearchModified = vendorsItems.filter(vend => vend.name.toLowerCase().includes(e.target.value.toLowerCase()));
            }
            else{
                vendorSearchModified = vendorList.filter(vend => vend.name.toLowerCase().includes(e.target.value.toLowerCase()));
            }

            refreshVendors(vendorSearchModified);

        }
        else {
            refreshVendors(vendorsItems);
        }
    }


    //Вывод логотипов
    let refreshVendors = (vendorsView) => {
      //  console.log(NameSearch);
       // debugger
        if (vendorsView) {

           // console.log(vendorsView.length)
            setVendorsShow(vendorsView.map((vend, i) => <div key={i} className='col-md-2 col-sm-3 col-xs-6 team'>
                <div className='thumbnail'>{' '}
                    <Link to={`/${lang}/vendor/${vend.link}`} title={vend.name} >
                        <svg className='team-img' viewBox="0 0 145 55" width="145" height="55">
                            <rect x="1" y="1" width="143" height="53" rx="6" ry="6" stroke="rgb(179, 179, 179)" strokeWidth="1" fill="none" />
                            <image   href={`/img/vendors/${vend.img}`} x="0" y="0" width="145" height="55" />
                        </svg>
                    </Link>
                    {/*<Link to={`/${lang}/vendor/${vend.link}`} title={vend.name} >
                                    <img src={`/img/vendors/${vend.img}`} title={vend.name} alt={`logo-vendor-${vend.name}`} className='team-img' />
                                </Link>*/}
                </div>
            </div>))

        }
        else {
            setVendorsShow(vendorList ? vendorList.map((vend, i) => <div key={i} className='col-md-2 col-sm-3 col-xs-6 team'>
                <div className='thumbnail'>{' '}
                    <Link to={`/${lang}/vendor/${vend.link}`} title={vend.name} >
                        <svg className='team-img' viewBox="0 0 145 55" width="145" height="55">
                            <rect x="1" y="1" width="143" height="53" rx="6" ry="6" stroke="rgb(179, 179, 179)" strokeWidth="1" fill="none" />
                            <image   href={`/img/vendors/${vend.img}`} x="0" y="0" width="145" height="55" />
                        </svg>
                    </Link>
                    {/*<Link to={`/${lang}/vendor/${vend.link}`} title={vend.name} >
                                    <img src={`/img/vendors/${vend.img}`} title={vend.name} alt={`logo-vendor-${vend.name}`} className='team-img' />
                                </Link>*/}
                </div>
            </div>)

                : []);

        }
    }

    let text = GetText();

    return (
        <div id='team' className='text-center'>
            <div className='container'>
                <div className='col-md-12 section-title'>
                    <h2>{text ? text.Vendors.title : ""}</h2>
                    <br />
                </div>
                <div className='col-md-12 search-block'>
                    <form action="">
                        <div>
                            <label htmlFor="Country">{text ? text.Vendors.country : ""}</label>
                            <select name="" onChange={selectCountry}  id="Country">
                                { } <option value="">{text ? text.Vendors.all : ""}</option>
                                {countryListMod ? countryListMod.map((country, i) => <option key={i}
                                    value={country.id}>
                                    {lang === "ua" ? country.name_ua : ""}
                                    {lang === "ru" ? country.name_ru : ""}
                                    {lang === "en" ? country.name_en : ""}
                                    {lang === "az" ? country.name_az : ""}
                                    {lang === "al" ? country.name_al : ""}
                                    {lang === "am" ? country.name_am : ""}
                                    {lang === "ge" ? country.name_ge : ""}
                                    {lang === "kz" ? country.name_kz : ""}
                                    {lang === "kg" ? country.name_kg : ""}
                                    {lang === "mk" ? country.name_mk : ""}
                                    {lang === "md" ? country.name_md : ""}
                                    {lang === "mn" ? country.name_mn : ""}
                                    {lang === "si" ? country.name_si : ""}
                                    {lang === "tj" ? country.name_tj : ""}
                                    {lang === "tr" ? country.name_tr : ""}
                                    {lang === "tm" ? country.name_tm : ""}
                                    {lang === "uz" ? country.name_uz : ""}
                                    {lang === "hr" ? country.name_hr : ""}
                                    {lang === "rs" ? country.name_rs : ""}
                                    {lang === "bg" ? country.name_bg : ""}
                                </option>) : "loading"}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="Solution">{text ? text.Vendors.solution : ""}</label>
                            <select name="" onChange={selectSolution} id="Solution">
                                <option value="">{text ? text.Vendors.all : ""}</option>
                                {solutionList ? solutionList.map((solution, i) => <option key={i}
                                    value={solution.id}>
                                    {lang === "ua" ? solution.name_ua : ""}
                                    {lang === "ru" ? solution.name_ru : ""}
                                    {lang === "en" ? solution.name_en : ""}
                                    {lang === "az" ? solution.name_az : ""}
                                    {lang === "al" ? solution.name_al : ""}
                                    {lang === "am" ? solution.name_am : ""}
                                    {lang === "ge" ? solution.name_ge : ""}
                                    {lang === "kz" ? solution.name_kz : ""}
                                    {lang === "kg" ? solution.name_kg : ""}
                                    {lang === "mk" ? solution.name_mk : ""}
                                    {lang === "md" ? solution.name_md : ""}
                                    {lang === "mn" ? solution.name_mn : ""}
                                    {lang === "si" ? solution.name_si : ""}
                                    {lang === "tj" ? solution.name_tj : ""}
                                    {lang === "tr" ? solution.name_tr : ""}
                                    {lang === "tm" ? solution.name_tm : ""}
                                    {lang === "uz" ? solution.name_uz : ""}
                                    {lang === "hr" ? solution.name_hr : ""}
                                    {lang === "rs" ? solution.name_rs : ""}
                                    {lang === "bg" ? solution.name_bg : ""}
                                </option>) : "loading"}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="filterName">{text ? text.Vendors.name : ""}</label>
                            <input type="text" onChange={vendorsNameSearch} id="filterName" placeholder={text ? text.Vendors.namePlaceholder : ""} />
                        </div>
                    </form>
                </div>
                <div id='row'>
                    {vendorsShow ? vendorsShow : vendorList ? vendorList.map((vend, i) => <div key={i} className='col-md-2 col-sm-3 col-xs-6 team'>

                        <div className='thumbnail'>

                                {' '}
                                <Link to={`/${lang}/vendor/${vend.link}`} title={vend.name} >
                                    <svg className='team-img' viewBox="0 0 145 55" width="145" height="55">
                                        <rect x="1" y="1" width="143" height="53" rx="6" ry="6" stroke="rgb(179, 179, 179)" strokeWidth="1" fill="none" />
                                        <image   href={`/img/vendors/${vend.img}`} x="0" y="0" width="145" height="55" />
                                    </svg>
                                </Link>
                                {/*<Link to={`/${lang}/vendor/${vend.link}`} title={vend.name} >
                                    <img src={`/img/vendors/${vend.img}`} title={vend.name} alt={`logo-vendor-${vend.name}`} className='team-img' />
                                </Link>*/}

                            </div>
                        </div>) : "Loading..." }
                </div>
            </div>
        </div>
    )
}
