import React from 'react';
import {hydrate, render} from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { StateProvider } from './context/GlobalState.jsx';


const APP = ( <React.Fragment>
    <StateProvider>
        <App/>
    </StateProvider>
</React.Fragment>);



const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(APP, rootElement);
} else {
    render(APP, rootElement);
}

/*ReactDOM.render(
    <React.Fragment>
            <StateProvider>
                <App/>
            </StateProvider>
    </React.Fragment>,
    document.getElementById('root')
);*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
