import React from "react";
import {Navigation} from "./navigation";
import {Header} from "./header";
import {Features} from "./features";
import {Services} from "./services";
import {Vendors} from "./Vendors";
import {Gallery} from "./gallery";
import {About} from "./about";
import {News} from "./News";
import {Contact} from "./contact";
import {useParams} from "react-router-dom";

export const Home = () => {

    const {lng} = useParams();



  return (
      <div>
        <Navigation lng={lng} />
        <Header />
        <Features />
        <Services />
        <Vendors />
        <Gallery />
        <About />
        <News />
        <Contact />
      </div>
  );
};
