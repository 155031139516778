import {Container, Navbar, Spinner} from "react-bootstrap";
import React, {useContext, useEffect} from "react";
import {Link, useLocation} from "react-router-dom";
import {GetText} from "../data/lang";
import {useCookies} from "react-cookie";
import {StateContext} from "../context/GlobalState";
import {Fade} from "react-awesome-reveal";

export const Navigation = (props) => {

    let {setLang, lang, geo} = useContext(StateContext)
    const [cookies, setCookie] = useCookies(['lang']);
    let location = useLocation();
    let redirectUrl = "";

    //console.log(location.pathname.substr(1, 2));

    if (location.pathname !== "/404.html") {
        if (location.pathname !== "/404.html/") {
            redirectUrl = location.pathname.replace(/^.{3}/, '');
        }
    } else {
        console.log("false " + location.pathname);
    }

    useEffect(() => {

        //console.log(props.lng);
        //console.log(cookies.lang);
        //console.log(lang);

        if (props.lng !== "404.html") {
            if (location.pathname.substr(1, 2) !== "") {
                setLang(location.pathname.substr(1, 2));
            } else {

                if (cookies.lang) {
                    if (props.lng) {
                        setLang(props.lng)
                    } else {
                        setLang(cookies.lang)
                    }
                } else {
                    if (props.lng) {
                        setLang(props.lng)
                    } else {
                        setLang("en")
                    }
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cookies]);


    //фікс для меню(тимчасово)
    function openMenuItem(e) {
        if (window.screen.width <= 768) {
            const menuItem = document.querySelectorAll(".dropdown");
            if (e.target.classList.contains('show')) {
                e.target.classList.remove('show');
            } else {
                for (let i = 0; i < menuItem.length; i++) {
                    menuItem[i].classList.remove('show');
                }
                e.target.classList.add('show');
            }
        } else {
            return;
        }
    }


    let definePage = (p, i) => {
        if (p.link.includes("http")) {

            if (p.iso !== undefined) {
                // фильтр Азербайджана и Армении
                if ((geo === "az" && p.iso === "am") || (geo === "am" && p.iso === "az")) {
                } else {
                    return <a key={i} target="_blank" title={p.name} rel="noreferrer" href={p.link}>{p.flag ?
                        <img title={p.name} src={`/img/flags/${p.flag}`} alt={p.flag}/> : ""}{p.name}</a>
                }
            } else {
                return <a key={i} target="_blank" title={p.name} rel="noreferrer" href={p.link}>{p.flag ?
                    <img title={p.name} src={`/img/flags/${p.flag}`} alt={p.flag}/> : ""}{p.name}</a>
            }


        } else {
            if (p.iso !== undefined) {
                // фильтр Азербайджана и Армении
                if ((geo === "az" && p.iso === "am") || (geo === "am" && p.iso === "az")) {
                } else {
                    return <Link key={i} title={p.name} to={`/${lang}${p.link}`}>{p.flag ?
                        <img title={p.name} src={`/img/flags/${p.flag}`} alt={p.flag}/> : ""}{p.name}</Link>
                }
            } else {
                return <Link key={i} title={p.name} to={`/${lang}${p.link}`}>{p.flag ?
                    <img title={p.name} src={`/img/flags/${p.flag}`} alt={p.flag}/> : ""}{p.name}</Link>
            }
        }
    }

    //кастомний селект
    function openLangMenu(e) {
        e.target.parentNode.classList.toggle('is-active');

    }

    function selectChoose(e) {

        //баг с русским
        if (e.target.outerText === "Русский") {
            //         console.log(e.target.outerText);
            setLang("ru");
        }


        let countryData = e.target.innerHTML,
            currentText = e.target.closest('.lang-select').querySelector('.lang-select__curent'),
            select = e.target.closest('.lang-select'),
            othersLang = document.querySelectorAll('.select__item');
        currentText.innerHTML = countryData; // підставлення данних з option в select
        select.classList.remove('is-active');

        for (let i = 0; i < othersLang.length; i++) {
            othersLang[i].classList.remove('is-active');
        }
        e.target.classList.add('is-active');

    }


    let text = GetText();


    return (

        <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
            <Navbar bg="dark" variant="dark" className="silver-nav">
                <Container>
                    <div className='navbar-header'>
                        {/* {geo }{' | '}*/}
                        {/*<a className='page-scroll' title="tel" href='tel:+380445949898'>
                            <i className="fa fa-phone"></i> +38 (044) 594 98 98,
                        </a>{' '}
                        <a className='page-scroll' title="tel" href='tel:+380444922929'>
                            +38 (044) 492 29 29
                        </a>{' '}*/}
                    </div>

                    <div className='navbar-collapse lang-block'>
                        <div className="navbar-right">


                            <div className="lang-select" onClick={openLangMenu}>
                                <div className="lang-select__header">
                                    {lang === "en" ? <div className="lang-select__curent"><img src="/img/flags/en.svg"
                                                                                               className="select__flag"
                                                                                               alt=""/> English
                                    </div> : ""}
                                    {lang === "ua" ? <div className="lang-select__curent"><img src="/img/flags/ua.png"
                                                                                               className="select__flag"
                                                                                               alt=""/> Українська
                                    </div> : ""}
                                    {lang === "ru" ? <div className="lang-select__curent"><img src="/img/flags/ru.svg"
                                                                                               className="select__flag"
                                                                                               alt=""/> Русский
                                    </div> : ""}
                                    {lang === "az" ? <div className="lang-select__curent"><img src="/img/flags/az.svg"
                                                                                               className="select__flag"
                                                                                               alt=""/> Azərbaycan
                                    </div> : ""}
                                    {lang === "al" ? <div className="lang-select__curent"><img src="/img/flags/al.png"
                                                                                               className="select__flag"
                                                                                               alt=""/> Shqiptare
                                    </div> : ""}
                                    {lang === "am" ? <div className="lang-select__curent"><img src="/img/flags/am.svg"
                                                                                               className="select__flag"
                                                                                               alt=""/> Հայերեն
                                    </div> : ""}
                                    {lang === "bg" ? <div className="lang-select__curent"><img src="/img/flags/bg.png"
                                                                                               className="select__flag"
                                                                                               alt=""/> Български
                                    </div> : ""}
                                    {lang === "ge" ? <div className="lang-select__curent"><img src="/img/flags/ge.svg"
                                                                                               className="select__flag"
                                                                                               alt=""/> ქართული
                                    </div> : ""}
                                    {lang === "kz" ? <div className="lang-select__curent"><img src="/img/flags/kz.png"
                                                                                               className="select__flag"
                                                                                               alt=""/> қазақ
                                    </div> : ""}
                                    {lang === "kg" ? <div className="lang-select__curent"><img src="/img/flags/kg.png"
                                                                                               className="select__flag"
                                                                                               alt=""/> Кыргызча
                                    </div> : ""}
                                    {lang === "mk" ? <div className="lang-select__curent"><img src="/img/flags/mk.png"
                                                                                               className="select__flag"
                                                                                               alt=""/> Македонски
                                    </div> : ""}
                                    {lang === "md" ? <div className="lang-select__curent"><img src="/img/flags/md.png"
                                                                                               className="select__flag"
                                                                                               alt=""/> Румынский
                                    </div> : ""}
                                    {lang === "mn" ? <div className="lang-select__curent"><img src="/img/flags/mn.png"
                                                                                               className="select__flag"
                                                                                               alt=""/> Монгол
                                    </div> : ""}
                                    {lang === "rs" ? <div className="lang-select__curent"><img src="/img/flags/rs.png"
                                                                                               className="select__flag"
                                                                                               alt=""/> Српски
                                    </div> : ""}
                                    {lang === "si" ? <div className="lang-select__curent"><img src="/img/flags/si.png"
                                                                                               className="select__flag"
                                                                                               alt=""/> Slovenščina
                                    </div> : ""}
                                    {lang === "tj" ? <div className="lang-select__curent"><img src="/img/flags/tj.png"
                                                                                               className="select__flag"
                                                                                               alt=""/> Тоҷикӣ
                                    </div> : ""}
                                    {lang === "tr" ? <div className="lang-select__curent"><img src="/img/flags/tr.svg"
                                                                                               className="select__flag"
                                                                                               alt=""/> Türk</div> : ""}
                                    {lang === "tm" ? <div className="lang-select__curent"><img src="/img/flags/tm.png"
                                                                                               className="select__flag"
                                                                                               alt=""/> Türkmenler
                                    </div> : ""}
                                    {lang === "uz" ? <div className="lang-select__curent"><img src="/img/flags/uz.svg"
                                                                                               className="select__flag"
                                                                                               alt=""/> o'zbek
                                    </div> : ""}
                                    {lang === "hr" ? <div className="lang-select__curent"><img src="/img/flags/hr.png"
                                                                                               className="select__flag"
                                                                                               alt=""/> Hrvatski
                                    </div> : ""}
                                    <i className="fa fa-chevron-down dropdown-icon"></i>
                                </div>
                                <div className="lang-select__body" onClick={selectChoose}>
                                    <Link title="en" className="lang-select__item" to={`/en${redirectUrl}`}
                                          onClick={() => setCookie('lang', "en", {path: '/'})}>
                                        <div className="select__item"><img src="/img/flags/en.svg"
                                                                           className="select__flag" alt=""/> English
                                        </div>
                                    </Link>
                                    <Link title="az" className="lang-select__item" to={`/az${redirectUrl}`}
                                          onClick={() => setCookie('lang', "az", {path: '/'})}>
                                        <div className="select__item"><img src="/img/flags/az.svg"
                                                                           className="select__flag" alt=""/> Azərbaycan
                                        </div>
                                    </Link>
                                    <Link title="al" className="lang-select__item" to={`/al${redirectUrl}`}
                                          onClick={() => setCookie('lang', "al", {path: '/'})}>
                                        <div className="select__item"><img src="/img/flags/al.png"
                                                                           className="select__flag" alt=""/> Shqiptare
                                        </div>
                                    </Link>
                                    <Link title="am" className="lang-select__item" to={`/am${redirectUrl}`}
                                          onClick={() => setCookie('lang', "am", {path: '/'})}>
                                        <div className="select__item"><img src="/img/flags/am.svg"
                                                                           className="select__flag" alt=""/> Հայերեն
                                        </div>
                                    </Link>
                                    <Link title="bg" className="lang-select__item" to={`/bg${redirectUrl}`}
                                          onClick={() => setCookie('lang', "bg", {path: '/'})}>
                                        <div className="select__item"><img src="/img/flags/bg.png"
                                                                           className="select__flag" alt=""/> Български
                                        </div>
                                    </Link>
                                    <Link title="ge" className="lang-select__item" to={`/ge${redirectUrl}`}
                                          onClick={() => setCookie('lang', "ge", {path: '/'})}>
                                        <div className="select__item"><img src="/img/flags/ge.svg"
                                                                           className="select__flag" alt=""/> ქართული
                                        </div>
                                    </Link>
                                    <Link title="kz" className="lang-select__item" to={`/kz${redirectUrl}`}
                                          onClick={() => setCookie('lang', "kz", {path: '/'})}>
                                        <div className="select__item"><img src="/img/flags/kz.png"
                                                                           className="select__flag" alt=""/> қазақ
                                        </div>
                                    </Link>
                                    <Link title="kg" className="lang-select__item" to={`/kg${redirectUrl}`}
                                          onClick={() => setCookie('lang', "kg", {path: '/'})}>
                                        <div className="select__item"><img src="/img/flags/kg.png"
                                                                           className="select__flag" alt=""/> Кыргызча
                                        </div>
                                    </Link>
                                    <Link title="mk" className="lang-select__item" to={`/mk${redirectUrl}`}
                                          onClick={() => setCookie('lang', "mk", {path: '/'})}>
                                        <div className="select__item"><img src="/img/flags/mk.png"
                                                                           className="select__flag" alt=""/> Македонски
                                        </div>
                                    </Link>
                                    <Link title="md" className="lang-select__item" to={`/md${redirectUrl}`}
                                          onClick={() => setCookie('lang', "md", {path: '/'})}>
                                        <div className="select__item"><img src="/img/flags/md.png"
                                                                           className="select__flag" alt=""/> Румынский
                                        </div>
                                    </Link>
                                    <Link title="mn" className="lang-select__item" to={`/mn${redirectUrl}`}
                                          onClick={() => setCookie('lang', "mn", {path: '/'})}>
                                        <div className="select__item"><img src="/img/flags/mn.png"
                                                                           className="select__flag" alt=""/> Монгол
                                        </div>
                                    </Link>
                                    <Link title="ru" className="lang-select__item" to={`/ru${redirectUrl}`}
                                          onClick={() => setCookie('lang', "ru", {path: '/'})}>
                                        <div className="select__item"><img src="/img/flags/ru.svg"
                                                                           className="select__flag" alt=""/> Русский
                                        </div>
                                    </Link>
                                    <Link title="rs" className="lang-select__item" to={`/rs${redirectUrl}`}
                                          onClick={() => setCookie('lang', "rs", {path: '/'})}>
                                        <div className="select__item"><img src="/img/flags/rs.png"
                                                                           className="select__flag" alt=""/> Српски
                                        </div>
                                    </Link>
                                    <Link title="si" className="lang-select__item" to={`/si${redirectUrl}`}
                                          onClick={() => setCookie('lang', "si", {path: '/'})}>
                                        <div className="select__item"><img src="/img/flags/si.png"
                                                                           className="select__flag" alt=""/> Slovenščina
                                        </div>
                                    </Link>
                                    <Link title="tj" className="lang-select__item" to={`/tj${redirectUrl}`}
                                          onClick={() => setCookie('lang', "tj", {path: '/'})}>
                                        <div className="select__item"><img src="/img/flags/tj.png"
                                                                           className="select__flag" alt=""/> Тоҷикӣ
                                        </div>
                                    </Link>
                                    <Link title="tr" className="lang-select__item" to={`/tr${redirectUrl}`}
                                          onClick={() => setCookie('lang', "tr", {path: '/'})}>
                                        <div className="select__item"><img src="/img/flags/tr.svg"
                                                                           className="select__flag" alt=""/> Türk
                                        </div>
                                    </Link>
                                    <Link title="tm" className="lang-select__item" to={`/tm${redirectUrl}`}
                                          onClick={() => setCookie('lang', "tm", {path: '/'})}>
                                        <div className="select__item"><img src="/img/flags/tm.png"
                                                                           className="select__flag" alt=""/> Türkmenler
                                        </div>
                                    </Link>
                                    <Link title="uz" className="lang-select__item" to={`/uz${redirectUrl}`}
                                          onClick={() => setCookie('lang', "uz", {path: '/'})}>
                                        <div className="select__item"><img src="/img/flags/uz.svg"
                                                                           className="select__flag" alt=""/> o'zbek
                                        </div>
                                    </Link>
                                    <Link title="ua" className="lang-select__item" to={`/ua${redirectUrl}`}
                                          onClick={() => setCookie('lang', "ua", {path: '/'})}>
                                        <div className="select__item"><img src="/img/flags/ua.png"
                                                                           className="select__flag" alt=""/> Українська
                                        </div>
                                    </Link>
                                    <Link title="hr" className="lang-select__item" to={`/hr${redirectUrl}`}
                                          onClick={() => setCookie('lang', "hr", {path: '/'})}>
                                        <div className="select__item"><img src="/img/flags/hr.png"
                                                                           className="select__flag" alt=""/> Hrvatski
                                        </div>
                                    </Link>


                                </div>


                            </div>

                        </div>
                    </div>
                </Container>
            </Navbar>
            <Fade>
                <div className='container'>
                    <div className='navbar-header'>
                        <button type='button' className='navbar-toggle collapsed' data-toggle='collapse'
                                data-target='#bs-example-navbar-collapse-1'>
                            {' '}
                            <span className='sr-only'></span>{' '}
                            <span className='icon-bar'></span>{' '}
                            <span className='icon-bar'></span>{' '}
                            <span className='icon-bar'></span>{' '}
                        </button>
                        <Link className='navbar-brand' title={lang} to={`/${lang}/`}>
                            <img alt='MUK' title="MUK" className='logo' src="/img/logo_muk.png"/>
                        </Link>{' '}
                    </div>

                    <div
                        className='collapse navbar-collapse'
                        id='bs-example-navbar-collapse-1'
                    >
                        <ul className='nav navbar-nav navbar-right'>

                            {text.Navigation
                                ? text.Navigation.map((d, i) => (
                                    <li key={i} className="dropdown" onClick={openMenuItem}>
                                        <a href={d.href} title={d.href} className='page-scroll  menu-itm no-link'>
                                            {text.Navigation ? d.menu : 'Loading'}
                                            <i className="fa fa-chevron-down dropdown-icon"></i>
                                        </a>
                                        <div className="dropdown-content">
                                            {d.podmenu ? d.podmenu.map((p, i) => (definePage(p, i))) : 'Loading...'}
                                        </div>
                                    </li>
                                ))
                                : <Spinner animation="border"/>}
                        </ul>
                    </div>
                </div>
            </Fade>
        </nav>
    )
}

