import React, {useContext, useState} from "react";
import { StateContext } from "../context/GlobalState.jsx";
import {Link} from "react-router-dom";
import {GetText} from "../data/lang";

export const News = () => {

    const {newsList, lang} = useContext(StateContext);
    const [newsCount, setNewsCount] = useState(6);
    let text = GetText();

    return (
        <div id='testimonials'>
            <div className='container'>
                <div className='section-title text-center'>
                    <h2>{text ? text.News.title : ""}</h2>
                </div>
                <div className='row same-height'>

                    {newsList.length > 0 ? newsList.slice(0, newsCount).map((news, i) => (
                            <div key={i} className='col-md-4 col-sm-6 col-xs-12 border-style'>
                                <Link to={`/${lang}/news/${news.id}`} title={news.title_en} >
                                    <div className='testimonial'>
                                        {news.date.length ? news.date : ""}
                                        <div className='testimonial-image'>
                                            {' '}

                                                <svg className='team-img' viewBox="0 0 100 38" width="100" height="38">
                                                    <rect x="1" y="1" width="99" height="37" rx="6" ry="6" stroke="rgb(179, 179, 179)" stroke-width="1" fill="white" />
                                                    <image   href={`/img/vendors/${news.image}`} x="0" y="0" width="100" height="38" />
                                                </svg>

                                            {/*<img title={news.title_en} alt={news.title_en} src={news.image ? `/img/vendors/${news.image}` : "/img/vendors/muk.png" }  />{' '}
                                            */}<div>
                                                <p>{news.type === "1" ? text.News.news : "" }
                                                    {news.type === "2" ? text.News.akcii : "" }
                                                </p>
                                                <div>
                                                    {news.for_country !== "0" ? <img className="flag-ico" alt={news.for_country} title={news.for_country} src={`/img/flags/${news.for_country}.svg`} /> : "" }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='testimonial-content'>
                                            <div className='testimonial-meta'>
                                                { lang === "ua" ? news.title_ua : ""}
                                                { lang === "ru" ? news.title_ru : ""}
                                                { lang === "en" ? news.title_en : ""}
                                                { lang === "az" ? news.title_az : ""}
                                                { lang === "al" ? news.title_al : ""}
                                                { lang === "am" ? news.title_am : ""}
                                                { lang === "ge" ? news.title_ge : ""}
                                                { lang === "kz" ? news.title_kz : ""}
                                                { lang === "kg" ? news.title_kg : ""}
                                                { lang === "mk" ? news.title_mk : ""}
                                                { lang === "md" ? news.title_md : ""}
                                                { lang === "mn" ? news.title_mn : ""}
                                                { lang === "si" ? news.title_si : ""}
                                                { lang === "tj" ? news.title_tj : ""}
                                                { lang === "tr" ? news.title_tr : ""}
                                                { lang === "tm" ? news.title_tm : ""}
                                                { lang === "uz" ? news.title_uz : ""}
                                                { lang === "hr" ? news.title_hr : ""}
                                                { lang === "rs" ? news.title_rs : ""}
                                                { lang === "bg" ? news.title_bg : ""}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))
                        : "Loading..."}

                </div>
                { newsCount > 13 ? <Link to={`/${lang}/all-news`} type='button' className='btn btn-custom btn-lg'>{text ? text.News.showAll : ""}</Link> :
                    <button onClick={() => setNewsCount(newsCount + 6)} type='button' className='btn btn-custom btn-lg'>{text ? text.News.showMore : ""}</button> }

            </div>
        </div>
    )
}
