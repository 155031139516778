/*import Fade from 'react-reveal/Fade';*/
import React from "react";
import {GetText} from "../data/lang";

export const Features = () => {

    let text = GetText();

    return (
        <div id='features' className='text-center'>

                <div className='container'>
         {/*           <Fade left cascade>*/}
                    <div className='col-md-10 col-md-offset-1 section-title'>
                    </div>
                    <div className='row'>
                        {text.Features
                            ? text.Features.map((d, i) => (
                                <div key={`${d.title}-${i}`} className='col-lg-3 col-xs-12 col-md-6 bloki'>
                                    {' '}
                                    <i className={d.icon}></i>
                                    <h3>{d.title}</h3>
                                    <p>{d.text}</p>
                                </div>
                            ))
                            : "Loading..."}
                    </div>
                    <br/>
                 {/*   </Fade>*/}
                </div>

        </div>
    )
}
