import React, {useContext, useEffect} from "react";
/*import Fade from "react-reveal/Fade";*/
import { StateContext } from "../../context/GlobalState.jsx";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Navigation} from "../navigation";
import {Footer} from "../Footer";
import ReactPaginate from "react-paginate";
import {GetText} from "../../data/lang";
import {Helmet} from "react-helmet";

export const AllNews = (props) => {
    const {page, lng} = useParams();
    const history = useNavigate();
    const {newsList, newsListStart, newsListEnd, setNewsListStart, setNewsListEnd, lang} = useContext(StateContext);
    const count = 24;

    const handlePageClick = (e) => {
        history(`/${lang}/all-news/${e.selected}`);
    };

    useEffect(() => {
        if(page && page < (newsList.length / count)){
            setNewsListStart(page * count);
            setNewsListEnd(page * count + count);
        }
        else{
            setNewsListStart(0 * count);
            setNewsListEnd(0 * count + count);
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    useEffect(() => {
        if(page && page < (newsList.length / count)){
            setNewsListStart(page * count);
            setNewsListEnd(page * count + count);
        }
        else{
            setNewsListStart(0 * count);
            setNewsListEnd(0 * count + count);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newsList])

    let text = GetText();

    return (
        <div>
            <Helmet htmlAttributes={{ lang : lng }}>
                <title>MUK | {text.News.title}</title>
                <meta name="description" content={text.News.title} />
                <meta name="keywords"  content='мук, muk, news, новости' />

            </Helmet>
            <Navigation lng={lng}/>
            <div className="vendor-detail menu-indent">
                <div className="vendor-detail_top">
                    <div className="container">
                        <h3>

                        </h3>
                    </div>
                </div>
            </div>
            <div id='testimonials'>
                <div className='container'>
                    <div className='section-title text-center'>
                        <h2>{text.News.title}</h2>

                    </div>
                    <div className='row same-height allnews'>
                        {/*    <Fade bottom cascade>*/}
                        {newsList.length > 2
                            ? newsList.slice(newsListStart, newsListEnd).map((news, i) => (
                                <div key={i} className='col-md-4 col-sm-6 col-xs-12 border-style'>
                                    <Link title={news.title_en} to={`/${lang}/news/${news.id}`}>

                                        <div className='testimonial'>
                                            {news.date.length ? news.date : ""}
                                            <div className='testimonial-image'>
                                                {' '}
                                                <svg className='team-img' viewBox="0 0 100 38" width="100" height="38">
                                                    <rect x="1" y="1" width="99" height="37" rx="6" ry="6" stroke="rgb(179, 179, 179)" stroke-width="1" fill="white" />
                                                    <image   href={`/img/vendors/${news.image}`} x="0" y="0" width="100" height="38" />
                                                </svg>
                                                {/*<img title={news.title_en} alt={news.title_en} src={news.image ? `/img/vendors/${news.image}` : "/img/vendors/muk.png" }  />{' '}
                                                */}
                                                <div>
                                                    <p>{news.type === "1" ? text.News.news : "" }
                                                        {news.type === "2" ? text.News.akcii : "" }
                                                    </p>
                                                    <div>
                                                        {news.for_country !== "0" ? <img className="flag-ico" alt={news.for_country} title={news.for_country} src={`/img/flags/${news.for_country}.svg`} /> : "" }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='testimonial-content'>
                                                <div className='testimonial-meta'>
                                                    { lang === "ua" ? news.title_ua : ""}
                                                    { lang === "ru" ? news.title_ru : ""}
                                                    { lang === "en" ? news.title_en : ""}
                                                    { lang === "az" ? news.title_az : ""}
                                                    { lang === "al" ? news.title_al : ""}
                                                    { lang === "am" ? news.title_am : ""}
                                                    { lang === "ge" ? news.title_ge : ""}
                                                    { lang === "kz" ? news.title_kz : ""}
                                                    { lang === "kg" ? news.title_kg : ""}
                                                    { lang === "mk" ? news.title_mk : ""}
                                                    { lang === "md" ? news.title_md : ""}
                                                    { lang === "mn" ? news.title_mn : ""}
                                                    { lang === "si" ? news.title_si : ""}
                                                    { lang === "tj" ? news.title_tj : ""}
                                                    { lang === "tr" ? news.title_tr : ""}
                                                    { lang === "tm" ? news.title_tm : ""}
                                                    { lang === "uz" ? news.title_uz : ""}
                                                    { lang === "hr" ? news.title_hr : ""}
                                                    { lang === "rs" ? news.title_rs : ""}
                                                    { lang === "bg" ? news.title_bg : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))
                            : "Loading..." }
                        {newsList.length > 2 ?
                            <div className="menu-indent">
                                <div className="text-center">
                                    <div className="container">
                                        <ReactPaginate
                                            hrefBuilder={(page) => page >= 1 && page <= (newsList.length / count) ? `/${lang}/all-news/${page}` : '#'}
                                            hrefAllControls
                                            initialPage={page}
                                            previousLabel={"prev"}
                                            nextLabel={"next"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={newsList.length / count}
                                            marginPagesDisplayed={1}
                                            pageRangeDisplayed={2}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"} />
                                    </div>
                                </div>
                            </div>
                            : ""}
                        {/* </Fade>*/}
                    </div>
                    {  }

                </div>

                <br />  <br />
                <Footer/>
            </div>
        </div>
    )
}
