import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {Home} from "./components/Home";
import {CountryDetail} from "./components/pages/countryDetail";
import {VendorDetail} from "./components/pages/vendorDetail";
import {SolutionDetail} from "./components/pages/solutionDetail";
import {VacaniesDetail} from "./components/pages/vacaniesDetail";
import {NewsDetail} from "./components/pages/newsDetail";
import {Vacanies} from "./components/pages/Vacancies";
import {MukReview} from "./components/pages/mukReview";
import {AllNews} from "./components/pages/AllNews";
import {VendorsPage} from "./components/pages/VendorsPage";
import {SolutionsPage} from "./components/pages/SolutionsPage";
import {AboutPage} from "./components/pages/AboutPage";
import {Privacy} from "./components/pages/Privacy";
import ScrollToTop from "./ScrollToTop";
import '../src/css/bootstrap.css';
import '../src/css/style.css';
import '../src/fonts/font-awesome/css/font-awesome.css';
import '../src/css/nivo-lightbox/nivo-lightbox.css';
import '../src/css/nivo-lightbox/default.css';
import {DistributionPage} from "./components/pages/DistributionPage";
import {PartnerPage} from "./components/pages/PartnerPage";
import {ResumePage} from "./components/pages/ResumePage";


const App = () => {

    return (
        <div className="appfull">
            <BrowserRouter>
                <ScrollToTop />
                <Routes>
                   {/* <Route path="/" element={<Navigate to="/en/" replace/>}/>*/}
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/:lng/" element={<Home />} />
                    <Route path="/:lng/country/:countryLink" element={<CountryDetail />} />
                    <Route exact path="/:lng/vendors" element={<VendorsPage/>} />
                    <Route path="/:lng/vendor/:vendorLink" element={<VendorDetail />} />
                    <Route exact path="/:lng/solutions" element={<SolutionsPage />} />
                    <Route path="/:lng/solution/:solutionLink" element={<SolutionDetail />} />
                    <Route path="/:lng/news/:newsLink" element={<NewsDetail />} />
                    <Route exact path="/:lng/all-news" element={<AllNews />} />
                    <Route path="/:lng/all-news/:page" element={<AllNews />} />
                    <Route exact path="/:lng/vacancies/" element={<Vacanies />} />
                    <Route path="/:lng/vacancies_for_region/:region" element={<Vacanies />} />
                    <Route path="/:lng/vacancies/:jobsLink" element={<VacaniesDetail />} />
                    <Route path="/:lng/muk-review" element={<MukReview />} />
                    <Route path="/:lng/about-muk" element={<AboutPage />} />
                    <Route path="/:lng/privacy" element={<Privacy />} />
                    <Route path="/:lng/distribution" element={<DistributionPage />} />
                    <Route path="/:lng/partner" element={<PartnerPage />} />
                    <Route path="/:lng/resume" element={<ResumePage />} />
                </Routes>
            </BrowserRouter>

        </div>
    );
};

export default App;
