import React, {useContext} from "react";
import {GetText} from "../data/lang";
import {StateContext} from "../context/GlobalState";
import {Link} from "react-router-dom";


export const Footer = () => {

    let text = GetText();
    const {lang} = useContext(StateContext);

    return (
            <div id='footer'>
                <div className='container text-center'>
                    <p>
                        <Link to={`/${lang}/privacy`} > &#32; </Link>&copy; 2008-{new Date().getFullYear()} MUK. {text ? text.Contact.footer : ""}
                    </p>
                </div>
            </div>
    )
}
